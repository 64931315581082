import React from 'react';
import { FormGroup, ControlLabel, FormControl, HelpBlock, Col } from 'react-bootstrap';

const FieldInput = ({ input, meta, type, placeholder, min, max, name, glyph, error, id, label }) => {
  var validationState=null,help=null;

  if (meta.touched&&meta.error){
    validationState="warning";
    help=(
      <HelpBlock>{meta.error}</HelpBlock>
    )
  }
  return (
    <FormGroup validationState={validationState}>
      <Col componentClass={ControlLabel} sm={4}>{label}</Col>
           {/* <ControlLabel>{label}</ControlLabel> */}
      <Col sm={8}>
        <FormControl
          id={id}
          type={type}
          name={name||null}
          placeholder={placeholder}
          min={min}
          max={max}
          value={input.value}
          onChange={input.onChange} 
        />
        {help}
      </Col>
    </FormGroup>
  )
}

export default FieldInput;