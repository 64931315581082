import {
  WORKSHEETS_GET_REQUEST, WORKSHEETS_GET_SUCCESS, WORKSHEETS_GET_FAIL,
  WORKSHEETS_PDF_REQUEST, WORKSHEETS_PDF_SUCCESS, WORKSHEETS_PDF_FAIL,
  WORKSHEET_GET_REQUEST, WORKSHEET_GET_SUCCESS, WORKSHEET_GET_FAIL,
  WORKSHEET_SET_REQUEST, WORKSHEET_SET_SUCCESS, WORKSHEET_SET_FAIL,
  WORKSHEET_DELETE_REQUEST, WORKSHEET_DELETE_SUCCESS, WORKSHEET_DELETE_FAIL,
  WORKSHEET_SET_STATE
} from './WorkSheetsActions';
import {USER_LOGOUT_SUCCESS} from '../Auth/AuthActions';

const initialState={
  worksheets_loading:false,
  worksheets_loaded:false,
  worksheets_pdf:null,
  worksheets_pdf_loading:false,
  count:0,
  total_count:0,
  items:[],
  worksheet_loading:false,
  worksheet_loaded:false,
  worksheet_deleting:false,
  worksheet_deleted:false,
  worksheet:null
};

export default (state=initialState,action)=>{
  switch (action.type){
    case WORKSHEETS_GET_REQUEST:
      return Object.assign({},state,{
        worksheets_loading:true,
      });
    case WORKSHEETS_GET_SUCCESS:
      return Object.assign({},state,{
        worksheets_loading:false,
        worksheets_loaded:true,
        count:action.response.count,
        total_count:action.response.total_count,
        worksheet:null,
        items:[...action.response.worksheets]
      });
    case WORKSHEETS_GET_FAIL:
      return Object.assign({},state,{
        worksheets_loading:false,
        worksheets_loaded:false,
        count:0,
        total_count:0,
        worksheet:null,
        items:[]
      });
    case WORKSHEETS_PDF_REQUEST:
      return Object.assign({},state,{
        worksheets_pdf:null,
        worksheets_pdf_loading:true,
      });
    case WORKSHEETS_PDF_SUCCESS:
      return Object.assign({},state,{
        worksheets_pdf:action.response,
        worksheets_pdf_loading:false,
      });
    case WORKSHEETS_PDF_FAIL:
      return Object.assign({},state,{
        worksheets_pdf:null,
        worksheets_pdf_loading:false,
      });
    case WORKSHEET_GET_REQUEST:
      return Object.assign({},state,{
        worksheet_loading:true,
        worksheet_loaded:false,
 //       worksheet:null
      });
    case WORKSHEET_GET_SUCCESS:
      return Object.assign({},state,{
        worksheet_loading:false,
        worksheet_loaded:true,
        worksheet:{...action.response.worksheet}
      });
    case WORKSHEET_GET_FAIL:
      return Object.assign({},state,{
        worksheet_loading:false,
        worksheet_loaded:false,
        worksheet:null
      });
      case WORKSHEET_SET_REQUEST:
      return Object.assign({},state,{
        worksheet_saving:true,
        worksheet_saved:false
      });
    case WORKSHEET_SET_SUCCESS:
      return Object.assign({},state,{
        worksheet_saving:false,
        worksheet_saved:true
      });
    case WORKSHEET_SET_FAIL:
      return Object.assign({},state,{
        worksheet_saving:false,
        worksheet_saved:false
      });
    case WORKSHEET_SET_STATE:
      return Object.assign({},state,{
        worksheet:{...action.worksheet}
      })
    case WORKSHEET_DELETE_REQUEST:
      return Object.assign({},state,{
        worksheet_deleting:true,
        worksheet_deleted:false
      });
    case WORKSHEET_DELETE_SUCCESS:
      return Object.assign({},state,{
        worksheet_deleting:false,
        worksheet_deleted:true
      });
    case WORKSHEET_DELETE_FAIL:
      return Object.assign({},state,{
        worksheet_deleting:false,
        worksheet_deleted:false
      });
    case USER_LOGOUT_SUCCESS:
      return Object.assign({},initialState);
    default:
      return state;
  }
}