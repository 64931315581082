import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, Form, FormGroup } from 'react-bootstrap';
import { DateTimePicker } from 'react-widgets';
import momentLocalizer from 'react-widgets-moment';
import moment from 'moment';
momentLocalizer(moment);

const required = value => (value === undefined ? 'Required' : undefined)

const renderDateTimePicker = ({ input: { onChange, value } }) =>
  <DateTimePicker
    time={false}
    onChange={onChange}
    format="MMMM YYYY"
    value={value}
    max={moment().toDate()}
    views={["year"]}
  />

const WorkSheetForm = (props)=>{
  const { handleSubmit, submitting } = props;
  return (
    <Form horizontal onSubmit={handleSubmit}>
      <FormGroup>
        <Field id="worksheet-month" name="month"
          type="text" component={renderDateTimePicker}
          validate={[required]}
        />
      </FormGroup>
      <FormGroup className="form-actions">
        <Button type="submit" bsStyle="primary" disabled={submitting}>Create</Button>
      </FormGroup>
    </Form>
  );
}

export default reduxForm({
  form:'worksheetForm'
})(WorkSheetForm);