import React, { Component } from 'react';
import { Table, Row, Col, Modal, Button } from 'react-bootstrap';
import IconButton from '../FormComponents/IconButton';

class Products extends Component{
  constructor(props){
    super(props);
    this.props.getProducts();
    this.state={
      deleteModal:false,
      modalMessage:"",
      modalCallback:()=>false,
      filter:[]
    }
  }

  render(){
    const closeModal=()=>{this.setState({deleteModal:false})}
    if (this.props.products_loaded){
      return (
        <div id="products">
            <Row>
              <Col xs={6}><h1>Products</h1></Col>
              {this.props.auth.type==='admin'&&<Col xs={6}><IconButton type="create" redirect="/products/new" right>Create</IconButton></Col>}
            </Row>
            <Row>
              <Col xs={12} sm={3}>
                <input className="form-control" type="text" placeholder="Filter" onChange={v=>{this.setState({filter:v.target.value.toLowerCase().split(" ")})}}/>
              </Col>
            </Row>
            <Table className="crud-table" responsive>
              <thead>
                <tr>
                  <th className="col-xs-1">Op Code</th>
                  <th className="col-xs-1">Op Desc</th>
                  <th className="col-md-1">Product Code</th>
                  <th className="col-md-1">Product Desc</th>
                  <th className="hidden-xs col-md-1">Department</th>
                  <th className="hidden-xs col-md-1">Category1</th>
                  <th className="col-xs-1 col-md-1">Salary (EUR)</th>
                  {this.props.auth.type==='admin'&&<th className="col-xs-1 col-md-1">Price (SEK)</th>}
                  <th className="col-xs-1 col-md-1">Pcs/h</th>
                  <th className="col-xs-1 col-md-1">Pcs/ppl</th>
                  <th className="col-xs-1 col-md-1">Shipping</th>
                  {this.props.auth.type==='admin'&&<th className="col-xs-2 col-md-1"> </th>}
                </tr>
              </thead>
              <tbody>
                {this.props.products.map(product=>{
                  let { prod_code, prod_desc, department, op_code, op_desc } = product;
                  let search_string = (op_code+" "+op_desc+" "+prod_code+" "+prod_desc+" "+department).toLowerCase();
                  let match = true;
                  this.state.filter.forEach(fi=>{
                    match = match && (search_string.indexOf(fi)>-1);
                  });
                  if (!match)return null;
                  return (
                    <tr key={product._id}>
                      <td>{product.op_code}</td>
                      <td>{product.op_desc}</td>
                      <td>{product.prod_code}</td>
                      <td>{product.prod_desc}</td>
                      <td className="hidden-xs">{product.department}</td>
                      <td className="hidden-xs">{product.category_1}</td>
                      <td>{product.cost_eur}</td>
                      {this.props.auth.type==='admin'&&<td>{product.price_sek}</td>}
                      <td>{product.pieces_per_hour}</td>
                      <td>{product.pieces_per_pallet}</td>
                      <td>{product.shipping.name}</td>
                      {this.props.auth.type==='admin'&&<td>
                        <IconButton type="delete" onClick={()=>{
                          this.setState({
                            deleteModal:true,
                            modalMessage:`Are you sure you want to delete ${product.op_code}? This action is permanent.`,
                            modalCallback:()=>{this.props.deleteProduct(product._id);closeModal();}
                          });
                        }}>Delete</IconButton>
                        <IconButton type="edit" redirect={"/products/"+product._id}>Edit</IconButton>
                      </td>}
                    </tr>
                  )
                  })}
              </tbody>
            </Table>
            <Modal show={this.state.deleteModal} onHide={closeModal}>
              <Modal.Header>Confirm delete...</Modal.Header>
              <Modal.Body>{this.state.modalMessage}</Modal.Body>
              <Modal.Footer>
                <Button onClick={closeModal}>Cancel</Button>
                <Button onClick={this.state.modalCallback}>Confirm</Button>
              </Modal.Footer>
            </Modal>
        </div>
      );
    }
    else {
      return (
        <div className="loading">
          Loading...
        </div>
      );
    }
  }
}

export default Products;