import React, { Component } from 'react';
import { Table, Row, Col, Modal, Button } from 'react-bootstrap';
import IconButton from '../FormComponents/IconButton';
import Pagination from 'react-js-pagination';
import { browserHistory } from 'react-router';
import { DropdownList, DateTimePicker } from 'react-widgets';
import moment from 'moment';
import FileSaver from 'file-saver';

const User = (props)=>{
  let {auth,userid,users,onChange} = props;
  if( auth.type ==="admin" || auth.type ==="supervisor"){
    let user=(users&&userid)?users.find(u=>u._id===userid):undefined;
    let all_users=[{name:'All'},...users];
    return <DropdownList name="user"
      data={all_users}
      value={user}
      textField={item=>{
        return !item?'':!item.userid?item.name:`${item.userid} - ${item.name}`
      }}
      filter="contains"
      onChange={onChange} />
  } else {
    return <p>{auth.name}</p>
  }
}

const Group = (props)=>{
  let {auth,group,users,onChange} = props;
  if( auth.type ==="admin" || auth.type ==="supervisor"){
    let all_groups=['All',...new Set((users||[]).map(u=>u.group))];
    return <DropdownList name="group"
      data={all_groups}
      value={group||'All'}
      filter="contains"
      onChange={onChange} />
  } else {
    return <p>{auth.group}</p>
  }
}


class WorkSheets extends Component{
  constructor(props){
    super(props);
    let fd,td;
    let {fromdate,todate,page,pagesize,userid,group,verified}=this.props.location.query;
    if(fromdate){
      fd = moment(fromdate+'01').toDate();
    } else {
      fd = moment().startOf('month').subtract(1,'months').toDate();
    }
    if(todate){
      td = moment(todate+'01').toDate();
    } else {
      td = moment().startOf('month').toDate();
    }
    this.state={
      deleteModal:false,
      modalMessage:"",
      modalCallback:()=>false,
      page:+page||1,
      pagesize:+pagesize||10,
      fromdate:fd,
      todate:td,
      userid:userid,
      group,
      verified:!verified?null:verified==='true'
    };
    let ut=this.props.user.type;
    if(ut === 'admin'||ut==='supervisor'){
      this.props.loadUsers();
    }
    let q={
      page:this.state.page,
      pagesize:this.state.pagesize,
      verified:this.state.verified,
      group:this.state.group,
      fromdate:moment(this.state.fromdate).format("YYYYMM"),
      todate:moment(this.state.todate).format("YYYYMM"),
      userid:this.state.userid
    };
    browserHistory.push({
      pathname: this.props.location.pathname,
      query: {
        ...this.props.location.query,
        ...q
      }
    })
    this.props.getWorkSheets(q);
  }

  componentWillReceiveProps(props){
    const {
      location: {query:{page,pagesize,userid,group,verified,fromdate,todate}}
    } = props;

    if (page !== this.props.location.query.page || 
      pagesize !== this.props.location.query.pagesize ||
      userid !== this.props.location.query.userid ||
      group !== this.props.location.query.group ||
      verified !== this.props.location.query.verified ||
      fromdate !== this.props.location.query.fromdate ||
      todate !== this.props.location.query.todate){
      return this.props.getWorkSheets({page,pagesize,userid,group,verified,fromdate,todate});
    }
    if (props.worksheets_pdf && !this.props.worksheets_pdf) {
      const filename = `SLTIC_worksheets_${moment(this.state.fromdate).format("YYYYMM")}_${moment(this.state.todate).format("YYYYMM")}.pdf`;
      FileSaver.saveAs(props.worksheets_pdf, filename, { autoBom: false });
    }
  }

  loadWorkSheets(){
    this.props.getWorkSheets({
      page:this.state.page,
      pagesize:this.state.pagesize,
      verified:this.state.verified,
      group:this.state.group,
      fromdate:moment(this.state.fromdate).format("YYYYMM"),
      todate:moment(this.state.todate).format("YYYYMM"),
      userid:this.state.userid
    });
  }
  
  handlePageChange(page,pagesize) {
    let s={page};
    pagesize && (s.pagesize=pagesize);
    this.setState(s);
    browserHistory.push({
      pathname: this.props.location.pathname,
      query: {
        ...this.props.location.query,
        ...s
      }
    })
  }

  handleDateChange(date,field){
    let d=moment(date).format("YYYYMM"),s={page:1};
    s[field]=date;
    this.setState(s);
    let url = {
      pathname: this.props.location.pathname,
      query: {
        ...this.props.location.query,
        page:1
      }
    };
    url.query[field]=d;
    browserHistory.push(url);
  }

  handleUserChange(user){
    this.setState({userid:user._id,page:1});
    browserHistory.push({
      pathname: this.props.location.pathname,
      query: {
        ...this.props.location.query,
        page:1,
        userid:user._id
      }
    })
  }

  handleGroupChange(group){
    group==='All'&&(group=null);
    this.setState({group,page:1});
    browserHistory.push({
      pathname: this.props.location.pathname,
      query: {
        ...this.props.location.query,
        page:1,
        group
      }
    })
  }

  handleVerifiedChange(opt){
    let verified=opt.v;
    this.setState({page:1,verified});
    browserHistory.push({
      pathname: this.props.location.pathname,
      query: {
        ...this.props.location.query,
        page:1,
        verified
      }
    })
  }

  downloadPDF(){
    this.props.getWorkSheetsPDF({
      verified:this.state.verified,
      group:this.state.group,
      fromdate:moment(this.state.fromdate).format("YYYYMM"),
      todate:moment(this.state.todate).format("YYYYMM"),
      userid:this.state.userid
    });
  }

  render(){
    const closeModal=()=>{this.setState({deleteModal:false})}
    if (this.props.worksheets_loaded){
      return (
        <div id="worksheets">
          <Row>
            <Col sm={6}>
              <h1>Work Sheets</h1>
            </Col>
            <Col sm={6}>
              <IconButton type="create" redirect="/worksheets/new" right>Create</IconButton>
            </Col>
          </Row>
          <Row>
            <Col sm={2}>
              <label htmlFor="fromdate">From</label>
              <DateTimePicker id="fromdate"
                time={false}
                onChange={d=>this.handleDateChange(d,'fromdate')}
                format="MMMM YYYY"
                value={this.state.fromdate}
                views={["year"]}
              />
            </Col>
            <Col sm={2}>
              <label htmlFor="fromdate">To</label>
              <DateTimePicker id="todate"
                time={false}
                onChange={d=>this.handleDateChange(d,'todate')}
                format="MMMM YYYY"
                value={this.state.todate}
                views={["year"]}
              />
            </Col>
            <Col sm={2}>
              <label htmlFor="group">Group</label>
              <Group auth={this.props.user} group={this.state.group} users={this.props.users} onChange={this.handleGroupChange.bind(this)}/>
            </Col>
            <Col sm={2}>
              <label htmlFor="user">User</label>
              <User auth={this.props.user} userid={this.state.userid} users={this.props.users} onChange={this.handleUserChange.bind(this)}/>
            </Col>
            <Col sm={1}>
              <label htmlFor="verified">Verified</label><br />
              <DropdownList name="verified"
                data={[{l:'All',v:null},{l:'Yes',v:true},{l:'No',v:false}]}
                value={this.state.verified}
                textField="l"
                valueField="v"
                onChange={this.handleVerifiedChange.bind(this)} />
            </Col>
            <Col sm={1}>
              <label htmlFor="pagesize">Page size</label>
              <DropdownList name="pagesize"
                data={[10,25,100]}
                value={this.state.pagesize}
                onChange={ps=>this.handlePageChange(1,ps)} />
            </Col>
            <Col sm={2} >
              <label>Records found (total)</label>
              <h4>{this.props.worksheets_count} ( {this.props.worksheets_total_count} )</h4>
            </Col>
            <Col sm={2}>
              {this.props.worksheets_loading &&
              <p>
                Loading...
              </p>}
            </Col>
          </Row>
          <hr />
          <Pagination
            activePage={this.state.page}
            itemsCountPerPage={this.state.pagesize}
            totalItemsCount={this.props.worksheets_count}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            />
          {(this.props.user.type ==="admin" || this.props.user.type ==="supervisor") &&
          <Button className="pull-right" disabled={this.props.worksheets_pdf_loading} onClick={() => this.downloadPDF()}>Download PDF</Button>}
          <Table className="crud-table" responsive>
            <thead>
              <tr>
                <th>User ID</th>
                <th>User</th>
                <th>Month</th>
                <th className="hidden-xs">Salary</th>
                <th className="hidden-xs">Bonus</th>
                <th>Salary Total</th>
                <th className="hidden-xs">Verified</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {this.props.worksheets.map(worksheet=>(
              <tr key={worksheet._id}>
                <td>{worksheet.user.userid}</td>
                <td>{worksheet.user.name}</td>
                {/* <td><LinkContainer to={`/worksheets/${worksheet._id}`}><a>{worksheet.month}</a></LinkContainer></td> */}
                <td>{moment(worksheet.month+'01').format('MMM-YY')}</td>
                <td className="hidden-xs">{worksheet.sum.toFixed(2)}</td>
                <td className="hidden-xs">{worksheet.bonus.toFixed(2)}</td>
                <td>{worksheet.sum_total.toFixed(2)}</td>
                <td className="hidden-xs">{worksheet.verified?'Yes':'No'}</td>
                <td>
                  <IconButton type="delete" onClick={()=>{
                    this.setState({
                      deleteModal:true,
                      modalMessage:`Are you sure you want to delete ${worksheet.month}? This action is permanent.`,
                      modalCallback:()=>{this.props.deleteWorkSheet(worksheet._id);this.loadWorkSheets();closeModal();}
                    });
                  }}>Delete</IconButton>
                  <IconButton type="edit" redirect={"/worksheets/"+worksheet._id}>Edit</IconButton>
                </td>
              </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            activePage={this.state.page}
            itemsCountPerPage={this.state.pagesize}
            totalItemsCount={this.props.worksheets_count}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            />
          <Modal show={this.state.deleteModal} onHide={closeModal}>
            <Modal.Header>Confirm delete...</Modal.Header>
            <Modal.Body>{this.state.modalMessage}</Modal.Body>
            <Modal.Footer>
              <Button onClick={closeModal}>Cancel</Button>
              <Button onClick={this.state.modalCallback}>Confirm</Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    }
    else {
      return (
        <div className="loading">
          Loading...
        </div>
      );
    }
  }
}

export default WorkSheets;