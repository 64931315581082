import Users from './Users.js';
import { connect } from 'react-redux';
import { getUsers, deleteUser } from './UsersActions';

const mapStateToProps=(state)=>{
  return {
    users_loaded:state.users.users_loaded,
    users:state.users.items,
    auth:state.auth.user
  }
}

const mapDispatchToProps=(dispatch)=>{
  return {
    loadUsers:()=>{dispatch(getUsers())},
    deleteUser:(id)=>{dispatch(deleteUser(id))}    
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Users);