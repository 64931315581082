import fetchPonyfill from 'fetch-ponyfill';
import qs from 'qs';
import { browserHistory } from 'react-router';
import {actions as notifActions} from 'redux-notifications';
import cfg from '../config.js'
import { loginUserFailure } from '../Auth/AuthActions';
const { fetch } = fetchPonyfill();


function callApi(endpoint, method, payload, authenticated, params, mimetype) {

  const mime = mimetype || 'application/json';
  const isjson = mime === 'application/json';
  let token = localStorage.getItem('token') || null;
  let config = {
    method:method||'GET',
    headers:{
      'Accept': mime,
      'Content-Type': 'application/json'
    },
    body:(payload===undefined)?undefined:JSON.stringify(payload)
  }

  if(authenticated) {
    if(token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    else {
      throw new Error("No token saved!")
    }
  }
  let url = cfg.API_SERVER + endpoint + (params?'?'+qs.stringify(params):'');
  
  return fetch(url, config)
    .then(response => {
      if (response.status === 401){
        return Promise.reject(response);
      }
      if (isjson) return response.json().then(obj => ({ obj, response }))
      return response.blob().then(obj => ({ obj, response }))
    }).then(({ obj, response }) => {
      if (isjson && !obj.success) return Promise.reject(obj);
      return obj;
    }).catch(err =>{
      console.log(err);
      return Promise.reject(err); 
    })
}

function asObject(t){return t?t.constructor===Object?t:{type:t}:null;}

export const CALL_API = Symbol('Call API')

export default store => next => action => {

  const callAPI = action[CALL_API];

  // So the middleware doesn't get applied to every single action
  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  let { endpoint, method, payload, actions, authenticated, params, mimetype } = callAPI;
  const notifSend = notifActions.notifSend;
  const requestAction = asObject(actions[0]);
  const successAction = asObject(actions[1]);
  const errorAction   = asObject(actions[2]);
  requestAction && store.dispatch(requestAction);

  // Passing the authenticated boolean back in our data will let us distinguish between normal and secret quotes
  return callApi(endpoint, method, payload, authenticated, params, mimetype)
    .then(response => {
      successAction.notif && notifSend(successAction.notif)(store.dispatch);
      successAction.route && browserHistory.push(successAction.route);
      successAction.action && store.dispatch(successAction.action);
      next({
        ...successAction,
        response,
        authenticated
      });
    })
    .catch(error => {
      if (error.status === 401) {
        store.dispatch(loginUserFailure(error.statusText))
      }
      let err = error.message || 'There was an error.';
      notifSend({
        message:err,
        kind:'danger',
        dismissAfter:2000
      })(store.dispatch);
      next({
        ...errorAction,
        error: err
      })
    })
}