import React, { Component } from 'react';
import LoginForm from '../Auth/LoginForm.js';
import { Row, Col, Panel, Alert, Image } from 'react-bootstrap';
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper';
import logo from '../Assets/slt-logo-ret.png';

const visibleOnlyUnAuth=connectedAuthWrapper({
  authenticatedSelector:state=>!state.auth.hash,
  wrapperDisplayName:'VisibleOnlyUnAuthenticated',
  FailureComponent:()=>null
});

const LoginPanel=visibleOnlyUnAuth((props)=>{
  return (
    <Row>
      <Col xs={12} sm={6} smOffset={3}>
        <Panel>
          <Image src={logo} responsive className="center-block"/>
          <Col xs={12}>
            <LoginForm
              formSubmit={props.authenticate.bind(this,props.location.query.redirect||"/")}
              password_expired={props.password_expired}
              />
            {props.auth_failed&&
              <Row style={{paddingTop: '1em'}}>
                <Alert bsStyle='danger'>
                  {props.message||'Authentication failed!'}
                </Alert>
              </Row>
            }
          </Col>
        </Panel>
      </Col>
    </Row>
  )
})

class Landing extends Component{
  constructor(props){
    super(props);
    this.state={
      login:false
    };
  }

  render(){
    return (
      <div>
        <LoginPanel 
          auth_failed={this.props.auth_failed} 
          message={this.props.message} 
          location={this.props.location} 
          authenticate={this.props.authenticate}
          password_expired={this.props.password_expired}
          />
      </div>
    )
  }
}

export default Landing;