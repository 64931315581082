import {CALL_API} from '../middleware/api';

export const REPORT_GET_REQUEST	  ='REPORT_GET_REQUEST';
export const REPORT_GET_SUCCESS	  ='REPORT_GET_SUCCESS';
export const REPORT_GET_FAIL   	  ='REPORT_GET_FAIL';

export function getReport(type,params){
  return {
    [CALL_API]:{
      endpoint:`/reports/${type+params}`,
      authenticated:true,
      actions:[REPORT_GET_REQUEST, REPORT_GET_SUCCESS, REPORT_GET_FAIL]
    }
  };
}