import {
  WORKITEM_GET_REQUEST, WORKITEM_GET_SUCCESS, WORKITEM_GET_FAIL,
  WORKITEM_SET_REQUEST, WORKITEM_SET_SUCCESS, WORKITEM_SET_FAIL,
  WORKITEM_DELETE_REQUEST, WORKITEM_DELETE_SUCCESS, WORKITEM_DELETE_FAIL
} from './WorkItemActions';

import {USER_LOGOUT_SUCCESS} from '../Auth/AuthActions';

const initialState={
  workitem_loading:false,
  workitem_loaded:false,
  workitem_saving:false,
  workitem_saved:false,
  workitem_deleting:false,
  workitem_deleted:false,
  workitem:null,
  error:null
};

export default (state=initialState,action)=>{
  switch (action.type){
    case WORKITEM_GET_REQUEST:
      return Object.assign({},state,{
        error:null,
        workitem_loading:true,
        workitem_loaded:false,
        worksheet:null
      });
    case WORKITEM_GET_SUCCESS:
      return Object.assign({},state,{
        error:null,
        workitem_loading:false,
        workitem_loaded:true,
        workitem:{...action.response.workitem}
      });
    case WORKITEM_GET_FAIL:
      return Object.assign({},state,{
        error:action.error,
        workitem_loading:false,
        workitem_loaded:false,
        workitem:null
      });
      case WORKITEM_SET_REQUEST:
      return Object.assign({},state,{
        error:null,
        workitem_saving:true,
        workitem_saved:false
      });
    case WORKITEM_SET_SUCCESS:
      return Object.assign({},state,{
        error:null,
        workitem_saving:false,
        workitem_saved:true
      });
    case WORKITEM_SET_FAIL:
      return Object.assign({},state,{
        error:action.error,
        workitem_saving:false,
        workitem_saved:false
      });
      case WORKITEM_DELETE_REQUEST:
      return Object.assign({},state,{
        error:null,
        workitem_deleting:true,
        workitem_deleted:false
      });
    case WORKITEM_DELETE_SUCCESS:
      return Object.assign({},state,{
        error:null,
        workitem_deleting:false,
        workitem_deleted:true
      });
    case WORKITEM_DELETE_FAIL:
      return Object.assign({},state,{
        error:action.error,
        workitem_deleting:false,
        workitem_deleted:false
      });
    case USER_LOGOUT_SUCCESS:
      return Object.assign({},initialState);
    default:
      return state;
  }
}