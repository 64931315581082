import WorkSheet from './WorkSheet';
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';
import { getWorkSheet, setWorkSheet } from './WorkSheetsActions';
import { deleteWorkItem } from '../WorkItem/WorkItemActions';

const mapStateToProps=(state)=>{
  return {
    initialValues:state.worksheets.worksheet,
    worksheet_saved:state.worksheets.worksheet_saved,
    bonus_rate:state.app.constants.bonus_rate,
    auth:state.auth.user
  }
}

const mapDispatchToProps=(dispatch)=>{
  return {
    getWorkSheet:(id)=>{dispatch(getWorkSheet(id))},
    setWorkSheet:(ws)=>{dispatch(setWorkSheet(ws))},
    deleteWorkItem:(wsid,itid)=>{dispatch(deleteWorkItem(wsid,itid))},
    goBack:()=>{dispatch(goBack())}
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(WorkSheet);