import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';
import Report from './Report';
import { getProducts } from '../Products/ProductsActions';

const mapStateToProps=(state)=>({
  report_types:state.app.constants.report_types,
  products:state.products.items,
  auth:state.auth.user
})

const mapDispatchToProps=(dispatch)=>({
  goBack(){dispatch(goBack())},
  getProducts:()=>{dispatch(getProducts())}
})

export default connect(mapStateToProps,mapDispatchToProps)(Report);