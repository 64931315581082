import React from 'react';
import { Row, Col, Panel, Alert } from 'react-bootstrap';
import WorkItemForm from './WorkItemForm';
import moment from 'moment';

class WorkItem extends React.Component {
  constructor(props){
    super(props);
    props.getProducts();
    props.getWorkItem(props.params.worksheet_id,props.params.id);
    if (!props.worksheet){
      if (!props.worksheets)props.getWorkSheet(props.params.worksheet_id);
      else {
        // Try loading from the cache of worksheets first, otherwise use the API.
        var ws=props.worksheets.filter(w=>w._id===props.params.worksheet_id);
        if (ws.length>0)props.setWorkSheetState(ws[0]);
        else props.getWorkSheet(props.params.worksheet_id);
      }
    }
  }

  componentWillReceiveProps(props){
    if(props.worksheet&&props.worksheet.month
      &&props.initialValues&&!props.initialValues.date){
      let today = moment();
      let date = today.format('YYYYMM')===''+props.worksheet.month?today:moment(props.worksheet.month+'01');
      props.initialValues.date=date.toDate();
    }
  } 


  render(){
    if (this.props.products_loaded){
      return (
      <div>
        <Row>
          <Col xs={12} sm={6} smOffset={3}>
            <Panel>
              <Col xs={12}>
                <WorkItemForm 
                  onSubmit={this.props.setWorkItem.bind(this,this.props.params.worksheet_id)} 
                  initialValues={this.props.initialValues}
                  products={this.props.products} 
                  working_time={this.props.working_time}
                  month={this.props.worksheet.month}
                  logout={this.props.logout}
                  goBack={this.props.goBack}
                />
                {this.props.error&&
                  <Row>
                    <Alert bsStyle='danger'>
                      {this.props.error}
                    </Alert>
                  </Row>
                }
              </Col>
            </Panel>
          </Col>
        </Row>
      </div>
    )
  } else {
    return (
      <div className="loading">
        Loading...
      </div>
    );
 
  }
  }
}

export default WorkItem;