import './Header.css';
import React, {Component} from 'react';
import { Navbar, Nav, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import logo from '../Assets/slt-logo.png'
//import { Link } from 'react-router';
const Navs=(props)=>{
  if(props.type==='user'){
    return <Nav>
      <LinkContainer to="/worksheets"><NavItem>Work Sheets</NavItem></LinkContainer>
      <LinkContainer to="/products"><NavItem>Products</NavItem></LinkContainer>
    </Nav>
  } else {
    return <Nav>
      <LinkContainer to="/worksheets"><NavItem>Work Sheets</NavItem></LinkContainer>
      <LinkContainer to="/users"><NavItem>Users</NavItem></LinkContainer>
      <LinkContainer to="/products"><NavItem>Products</NavItem></LinkContainer>
      <LinkContainer to="/reports"><NavItem>Reports</NavItem></LinkContainer>
    </Nav>
  }
}
class Header extends Component{
  render(){
    return (
      <Navbar style={{borderRadius:'0px'}}>
        <Navbar.Header>
          <Navbar.Brand>
            <img src={logo} alt="slt-logo" />
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
        <Navs type={this.props.user.type}/>
        <Nav pullRight>
          <LinkContainer to="/profile"><NavItem className="profile">{this.props.user.name}</NavItem></LinkContainer>
           <NavItem className="logout" onClick={this.props.logout}>Logout</NavItem> 
        </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default Header;