import App from './App.js';
import { connect } from 'react-redux';
import {getConstants} from './AppActions';
import {logoutAndRedirect} from '../Auth/AuthActions';

const mapStateToProps=(state)=>{
  return {
    user:state.auth.user
  }
}
const mapDispatchToProps=(dispatch)=>{
  return {
    getConstants:()=>{dispatch(getConstants())},
    logout:()=>{dispatch(logoutAndRedirect())}
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(App);