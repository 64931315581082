import React from 'react';
import { FormGroup, ControlLabel, FormControl, Col } from 'react-bootstrap';

const FieldStatic = ({ input, type, name, id, label }) => {
  return (
    <FormGroup>
      <Col componentClass={ControlLabel} sm={4}>{label}</Col>
      <Col sm={8}>
        <FormControl.Static
          id={id}
          name={name||null} 
          >
          {input.value}
        </FormControl.Static>
      </Col>
    </FormGroup>
  )
}

export default FieldStatic;