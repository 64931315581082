import React,{ Component } from 'react';
import { Row, Col, Panel } from 'react-bootstrap';
import ProfileForm from './ProfileForm';

class Profile extends Component{
  componentDidMount(){
    console.log(this.props.auth)
    this.props.getUser(this.props.auth._id);
  }

  render(){
    return (
      <Row>
        <Col xs={12} sm={6} smOffset={3}>
          <Panel>
            <Col xs={12}>
              <ProfileForm 
                onSubmit={this.props.setUser} 
                initialValues={this.props.user} 
                />
            </Col>
          </Panel>
        </Col>
      </Row>     
          )
  }
}

export default Profile;