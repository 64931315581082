import {CALL_API} from '../middleware/api';
import {getWorkSheet} from '../WorkSheets/WorkSheetsActions';
import {reset} from 'redux-form';

export const WORKITEM_GET_REQUEST	  ='WORKITEM_GET_REQUEST';
export const WORKITEM_GET_SUCCESS	  ='WORKITEM_GET_SUCCESS';
export const WORKITEM_GET_FAIL   	  ='WORKITEM_GET_FAIL';
export const WORKITEM_SET_REQUEST	  ='WORKITEM_SET_REQUEST';
export const WORKITEM_SET_SUCCESS	  ='WORKITEM_SET_SUCCESS';
export const WORKITEM_SET_FAIL   	  ='WORKITEM_SET_FAIL';
export const WORKITEM_DELETE_REQUEST	='WORKITEM_DELETE_REQUEST';
export const WORKITEM_DELETE_SUCCESS	='WORKITEM_DELETE_SUCCESS';
export const WORKITEM_DELETE_FAIL   	='WORKITEM_DELETE_FAIL';

export function getWorkItem(worksheet_id,id){
  if (id==="new") return {
    type: WORKITEM_GET_SUCCESS,
    response:{
      success:true,
      workitem:{}
    }
   };
  else return {
    [CALL_API]:{
      endpoint:'/worksheets/'+worksheet_id+'/items/'+id,
      authenticated:true,
      actions:[WORKITEM_GET_REQUEST, WORKITEM_GET_SUCCESS, WORKITEM_GET_FAIL]
    }
  };
}

export function setWorkItem(worksheet_id,workitem){
  return {
    [CALL_API]:{
      endpoint:'/worksheets/'+worksheet_id+(workitem._id?'/items/'+workitem._id:'/items'),
      method:workitem._id?'PUT':'POST',
      payload:workitem,
      authenticated:true,
      actions:[
        WORKITEM_SET_REQUEST, 
        {
          type:WORKITEM_SET_SUCCESS,
          action:reset('workItemForm'),
          route:workitem._id && '/worksheets/'+worksheet_id,
          notif:{
            message:'Saved work log',
            kind:'info',
            dismissAfter:2000
          }
        },
        WORKITEM_SET_FAIL
      ]
    }
  }
}

export function deleteWorkItem(worksheet_id,id){
  return {
    [CALL_API]:{
      endpoint:'/worksheets/'+worksheet_id+'/items/'+id,
      method:'DELETE',
      authenticated:true,
      actions:[
        WORKITEM_DELETE_REQUEST, 
        {
          type:WORKITEM_DELETE_SUCCESS,
          action:getWorkSheet(worksheet_id),
          notif:{
            message:'Deleted work log item',
            kind:'info',
            dismissAfter:2000
          }
        },
        WORKITEM_DELETE_FAIL
      ]
    }
  }
}

