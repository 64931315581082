import {CALL_API} from '../middleware/api';

export const PRODUCTS_GET_REQUEST   ='PRODUCTS_GET_REQUEST';
export const PRODUCTS_GET_SUCCESS   ='PRODUCTS_GET_SUCCESS';
export const PRODUCTS_GET_FAIL      ='PRODUCTS_GET_FAIL';
export const PRODUCT_GET_REQUEST	  ='PRODUCT_GET_REQUEST';
export const PRODUCT_GET_SUCCESS	  ='PRODUCT_GET_SUCCESS';
export const PRODUCT_GET_FAIL   	  ='PRODUCT_GET_FAIL';
export const PRODUCT_SET_REQUEST	  ='PRODUCT_SET_REQUEST';
export const PRODUCT_SET_SUCCESS	  ='PRODUCT_SET_SUCCESS';
export const PRODUCT_SET_FAIL   	  ='PRODUCT_SET_FAIL';
export const PRODUCT_DELETE_REQUEST	='PRODUCT_DELETE_REQUEST';
export const PRODUCT_DELETE_SUCCESS	='PRODUCT_DELETE_SUCCESS';
export const PRODUCT_DELETE_FAIL   	='PRODUCT_DELETE_FAIL';

export function getProducts(){
  return {
    [CALL_API]:{
      endpoint:'/products',
      authenticated:true,
      actions:[PRODUCTS_GET_REQUEST, PRODUCTS_GET_SUCCESS, PRODUCTS_GET_FAIL]
    }
  }
}

export function getProduct(id){
  if (id==="new") return {
    type: PRODUCT_GET_SUCCESS,
    response:{
      success:true,
      product:{}
    }
   };
  else return {
    [CALL_API]:{
      endpoint:'/products/'+id,
      authenticated:true,
      actions:[PRODUCT_GET_REQUEST, PRODUCT_GET_SUCCESS, PRODUCT_GET_FAIL]
    }
  };
}

export function setProduct(prod){
  return {
    [CALL_API]:{
      endpoint:prod._id?('/products/'+prod._id):'/products',
      method:prod._id?'PUT':'POST',
      payload:prod,
      authenticated:true,
      actions:[
        PRODUCT_SET_REQUEST, 
        {
          type:PRODUCT_SET_SUCCESS,
          route:'/products',
          notif:{
            message:`${prod._id?'Saved':'Created'} product: ${prod.op_code}-${prod.op_desc}-${prod.prod_code}-${prod.prod_desc}`,
            kind:'success',
            dismissAfter:2000
          }
        },
        PRODUCT_SET_FAIL
      ]
    }
  }
}

export function deleteProduct(id){
  return {
    [CALL_API]:{
      endpoint:'/products/'+id,
      method:'DELETE',
      authenticated:true,
      actions:[
        PRODUCT_DELETE_REQUEST,
        {
          type:PRODUCT_DELETE_SUCCESS,
          action:getProducts(),
          notif:{
            message:'Deleted product '+id,
            kind:'success',
            dismissAfter:2000
          }
        },
        PRODUCT_DELETE_FAIL
      ]
    }
  }
}

