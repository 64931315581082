import {CALL_API} from '../middleware/api';

export const USER_GET_REQUEST   ='USER_GET_REQUEST';
export const USER_GET_SUCCESS	  ='USER_GET_SUCCESS';
export const USER_GET_FAIL   	  ='USER_GET_FAIL';
export const USER_SET_REQUEST   ='USER_SET_REQUEST';
export const USER_SET_SUCCESS	  ='USER_SET_SUCCESS';
export const USER_SET_FAIL   	  ='USER_SET_FAIL';
export const USER_DELETE_REQUEST='USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS='USER_DELETE_SUCCESS';
export const USER_DELETE_FAIL   ='USER_DELETE_FAIL';
export const USERS_GET_REQUEST  ='USERS_GET_REQUEST';
export const USERS_GET_SUCCESS  ='USERS_GET_SUCCESS';
export const USERS_GET_FAIL     ='USERS_GET_FAIL';

export function getUsers(){
  return {
    [CALL_API]:{
      endpoint:'/users',
      authenticated:true,
      actions:[USERS_GET_REQUEST, USERS_GET_SUCCESS, USERS_GET_FAIL]
    }
  }
}

export function getUser(id){
  if (id==="new") return {
    type: USER_GET_SUCCESS,
    response:{
      success:true,
      user:{}
    }
   };
  else return {
    [CALL_API]:{
      endpoint:'/users/'+id,
      authenticated:true,
      actions:[
        USER_GET_REQUEST, 
        USER_GET_SUCCESS,
        USER_GET_FAIL
      ]
    }
  }
}

export function setUser(user){
  return {
    [CALL_API]:{
      endpoint:user._id?('/users/'+user._id):'/users',
      method:user._id?'PUT':'POST',
      payload:user,
      authenticated:true,
      actions:[
        USER_SET_REQUEST, 
        {
          type:USER_SET_SUCCESS,
          route:'/users',
          notif:{
            message:(user._id?'Saved':'Created')+' user: '+user.name,
            kind:'info',
            dismissAfter:2000
          }
        },
        USER_SET_FAIL
      ]
    }
  }
}

export function deleteUser(id){
  return {
    [CALL_API]:{
      endpoint:'/users/'+id,
      method:'DELETE',
      authenticated:true,
      actions:[
        USER_DELETE_REQUEST, 
        {
          type:USER_DELETE_SUCCESS,
          action:getUsers(),
          notif:{
            message:'Deleted user '+id,
            kind:'success',
            dismissAfter:2000
          }
        },
        USER_DELETE_FAIL
      ]
    }
  }
}

