import React, { Component } from 'react';
import UserForm from './UserForm.js';
import { Row, Col, Panel, Alert } from 'react-bootstrap';

class User extends Component{
  
  componentDidMount(){
    this.props.getProducts();
    this.props.getUser(this.props.params.id);
  }

  submitUser(user){
    this.props.setUser({
      ...user,
      type: user.type.value,
      locked: user.locked.value,
      password_expired: user.password_expired.value
    })
  }

  render(){
    return (
      <div>
        <Row>
          <Col xs={12} sm={6} smOffset={3}>
            <Panel>
              <Col xs={12}>
                <UserForm 
                  // onSubmit={this.props.setUser} 
                  onSubmit={this.submitUser.bind(this)} 
                  initialValues={this.props.initialValues} 
                  departments={this.props.departments} 
                  goBack={this.props.goBack}
                  auth={this.props.auth}
                  />
                {this.props.user_failed&&
                  <Row>
                    <Alert bsStyle='danger'>
                      User could not be loaded!
                    </Alert>
                  </Row>
                }
              </Col>
            </Panel>
            
          </Col>
        </Row>
      </div>
    )
  }
}

export default User;