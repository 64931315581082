import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer.js';
import { syncHistoryWithStore, routerMiddleware } from 'react-router-redux';
import api from './middleware/api';

export default (baseHistory,initialState)=>{
  const routing=routerMiddleware(baseHistory);
  const composeEnhancers=window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store=createStore(
    rootReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(thunk),
      applyMiddleware(api),
      applyMiddleware(routing)
    )
  );
  const history=syncHistoryWithStore(baseHistory,store);
  return {store,history};
}