import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, Form, FormGroup } from 'react-bootstrap';
import SelectListInput from '../FormComponents/SelectListInput';
import DateTimeInput from '../FormComponents/DateTimeInput';
import DropdownListInput from '../FormComponents/DropdownListInput';

const required = value => (value === undefined ? 'Required' : undefined)
const validProd = value => (value && value._id ? undefined : 'Required');
    
const ListItem = ({item}) => {
  if(!item._id) {
    return <span>Select</span>;
  } else {
    return (
      <div>
        <p><strong>Operation: </strong>{item.op_code} {item.op_desc&& ` - ${item.op_desc}`}</p>
        <p><strong>Article: </strong>{item.prod_code} {item.prod_desc&& ` - ${item.prod_desc}`}</p>
        <p><strong>Department: </strong>{item.department}</p>
      </div>
    );
  }
}

class ReportForm extends React.Component {
  constructor(props){
    super(props);
    this.state={
      required:[],
      optional:[],
      type:""
    }
  }

  render(){
    const { handleSubmit, pristine, reset, submitting, goBack, products } = this.props;  
    return (
      <Form horizontal onSubmit={handleSubmit}>
        <Field 
          id="type" 
          label="Report Type"
          name="type"
          textField='label'
          valueField='label'
          validate={[required]} 
          component={SelectListInput}
          data={this.props.report_types}
          onChange={(v)=>{this.setState({...v})}}
        />
        {[...this.state.required,...this.state.optional].includes("p_start")&&
        <Field 
          id="pstartdate" 
          name="start_date_p"
          label="Period Start Date"
          type="text" 
          date_format="YYYY-MM-DD"
          component={DateTimeInput}
          validate={[required]} 
        />
        }
        {[...this.state.required,...this.state.optional].includes("a_start")&&
        <Field 
          id="startdate" 
          name="start_date_a"
          label="Accumulation Start Date"
          type="text" 
          date_format="YYYY-MM-DD"
          component={DateTimeInput}
          validate={[required]} 
          />
        }
        {[...this.state.required,...this.state.optional].includes("a_end")&&
        <Field 
          id="enddate" 
          name="end_date"
          label="End Date"
          type="text" 
          date_format="YYYY-MM-DD"
          component={DateTimeInput}
          validate={[required]} 
          />
      }
      {[...this.state.required,...this.state.optional].includes("product")&&
      <Field
        id="product" name="product" label="Article no."
        className="form-control" 
        component={DropdownListInput} 
        textField={item=>{
          return !item.op_code?'':`${item.op_code} ${item.op_desc} ${item.prod_code} ${item.prod_desc} ${item.department}`
        }}
        valueField={item=>item&&item._id}
        filter={(x,y)=>{
          let s=y.toLowerCase().split(' ');
          let t=`${x.prod_code} ${x.prod_desc} ${x.op_code} ${x.op_desc} ${x.department}`.toLowerCase();
          return s.reduce((a,b)=>a&&t.indexOf(b)>-1,true);
        }} 
        itemComponent={ListItem}
        valueComponent={ListItem}
        groupBy='department'
        data={products}
        validate={[required,validProd]} />
      }
      <FormGroup className="form-actions">
          <Button type="submit" bsStyle="primary" disabled={pristine || submitting}>Submit</Button>
          <Button type="button" disabled={pristine || submitting} onClick={reset}>Undo Changes</Button>    
          <Button className="pull-right" onClick={goBack}>Back</Button>
        </FormGroup>
      </Form>
    );
  }
}

export default reduxForm({
  form:'reportForm'
})(ReportForm);