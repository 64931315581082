import WorkSheets from './WorkSheets.js';
import { connect } from 'react-redux';
import { getWorkSheets, getWorkSheetsPDF, deleteWorkSheet } from './WorkSheetsActions';
import { getUsers } from '../Users/UsersActions';

const mapStateToProps=(state)=>{
  return {
    worksheets_loaded:state.worksheets.worksheets_loaded,
    worksheets_loading:state.worksheets.worksheets_loading,
    worksheets_pdf:state.worksheets.worksheets_pdf,
    worksheets_pdf_loading:state.worksheets.worksheets_pdf_loading,
    worksheets:state.worksheets.items,
    worksheets_count:state.worksheets.count,
    worksheets_total_count:state.worksheets.total_count,
    user:state.auth.user,
    users_loaded:state.users.users_loaded,
    users:state.users.items
  }
}

const mapDispatchToProps=(dispatch)=>{
  return {
    loadUsers:()=>{dispatch(getUsers())},
    getWorkSheets:opts=>{dispatch(getWorkSheets(opts))},
    getWorkSheetsPDF:opts=>{dispatch(getWorkSheetsPDF(opts))},
    deleteWorkSheet:id=>{dispatch(deleteWorkSheet(id))}
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(WorkSheets);