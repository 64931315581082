import React, { Component } from 'react';
import ReportForm from './ReportForm.js';
import { Row, Col, Panel } from 'react-bootstrap';
import moment from 'moment';
import { browserHistory } from 'react-router';
import qs from 'qs'

class Report extends Component{
  constructor(props){
    super(props);
    props.getProducts();
    let now=new Date(),
      m=new Date(now.getFullYear(),now.getMonth()),
      y=new Date(now.getFullYear(),0);

    this.state={
      initialValues:{
        start_date_p:m,
        start_date_a:y,
        end_date:now
      }
    }
  }
  redirectToReport(values){
    const { op_code, prod_code } = values.product || {};
    let params=qs.stringify({
      type:values.type.id,
      start_date_p:moment(values.start_date_p).format("YYYYMMDD"),
      start_date_a:moment(values.start_date_a).format("YYYYMMDD"),
      end_date:moment(values.end_date).format("YYYYMMDD"),
      op_code,
      prod_code
    });
    browserHistory.push(`/reports/${values.type.id}?${params}`);
  }

  render(){
    let reports=this.props.report_types;
    if (this.props.auth.type !== 'admin') {
      reports = reports.filter( r => !(r.id === 'costing' || r.id === 'pre_costing'))
    }
    return (
      <div>
        <Row>
          <Col xs={12} sm={8} smOffset={2} md={6} mdOffset={3}>
            <Panel>
              <Col xs={12}>
                <ReportForm onSubmit={this.redirectToReport}
                  report_types={reports} 
                  initialValues={this.state.initialValues}
                  goBack={this.props.goBack}
                  products={this.props.products}
                  />
              </Col>
            </Panel>
          </Col>
        </Row>
      </div>
    )
  }
}

export default Report;