import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, Form, FormGroup } from 'react-bootstrap';
import FieldInput from '../FormComponents/FieldInput';
import DropdownListInput from '../FormComponents/DropdownListInput';

const required = value => (value === undefined ? 'Required' : undefined)
const minLength = min => value => value && value.length < min ? `Must be ${min} characters or more` : undefined
const minLength3 = minLength(3)

let ProductForm=props=>{
  const { handleSubmit, pristine, reset, submitting, goBack, shipping_costs} = props;
  return (
    <Form horizontal onSubmit={handleSubmit}>
      <Field 
        id="product-op-code" label="Operation Code" name="op_code"
        className="form-control" type="number" placeholder="10" 
        validate={[required]} component={FieldInput}/>
      <Field 
        id="product-op-desc" label="Operation Description" name="op_desc"
        className="form-control" type="text" placeholder="description" 
        validate={[required,minLength3]} component={FieldInput}/>
      <Field 
        id="product-art-code" label="Product Code" name="prod_code"
        className="form-control" type="text" placeholder="XXXXXXXX" 
        validate={[required,minLength3]} component={FieldInput}/>
      <Field 
        id="product-desc" label="Product Description" name="prod_desc"
        className="form-control" type="text" placeholder="description" 
        validate={[]} component={FieldInput}/>
      <Field 
        id="product-dep"  label="Department" name="department"
        className="form-control" type="text" placeholder="department" 
        validate={[required]} component={FieldInput}/>
      <Field 
        id="product-cat1"  label="Category1" name="category_1"
        className="form-control" type="text" placeholder="category" 
        validate={[required]} component={FieldInput}/>
      <Field 
        id="product-cost" label="Salary (EUR)" name="cost_eur"
        className="form-control" type="number" step="0.00000001" placeholder="0" 
        validate={[required]} component={FieldInput}/>
      <Field 
        id="product-price" label="Price (SEK)" name="price_sek"
        className="form-control" type="number" step="0.00000001" placeholder="0" 
        validate={[required]} component={FieldInput}/>
      <Field 
        id="product-pph" label="Pcs/h" name="pieces_per_hour"
        className="form-control" type="number" placeholder="0" 
        validate={[required]} component={FieldInput}/>
      <Field 
        id="product-ppp" label="Pcs/ppl" name="pieces_per_pallet"
        className="form-control" type="number" placeholder="0" 
        validate={[required]} component={FieldInput}/>
      <Field 
        id="product-shipping" 
        type="text" label="Shipping"
        name="shipping" 
        textField="name"
        validate={[required]} 
        component={DropdownListInput} 
        data={shipping_costs}
        />
      <FormGroup className="form-actions">
        <Button type="submit" bsStyle="primary" disabled={pristine || submitting}>Submit</Button>
        <Button type="button" disabled={pristine || submitting} onClick={reset}>Undo Changes</Button>    
        <Button className="pull-right" onClick={goBack}>Close</Button>
      </FormGroup>
    </Form>
  );
}

export default reduxForm({
  form:'productForm'
})(ProductForm);