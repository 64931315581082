import React from 'react';
import { FormGroup, ControlLabel, HelpBlock, Col } from 'react-bootstrap';
import { DropdownList } from 'react-widgets';

const DropdownListInput = ({ input, meta, label, data, valueField, textField, filter, groupBy, itemComponent, valueComponent }) =>{
  var validationState=null,help=null;

  if (meta.touched&&meta.error){
    validationState="warning";
    help=(
      <HelpBlock>{meta.error}</HelpBlock>
    )
  }
  return (
    <FormGroup validationState={validationState}>
      {/* <ControlLabel>{label}</ControlLabel> */}
      <Col componentClass={ControlLabel} sm={4}>{label}</Col>
      <Col sm={8}>    
        <DropdownList {...input}
          data={data}
          valueField={valueField}
          textField={textField}
          itemComponent={itemComponent}
          valueComponent={valueComponent}
          filter={filter}
          
          groupBy={groupBy}
          onChange={input.onChange} />
        {help}
      </Col>
    </FormGroup>
  )
}

export default DropdownListInput;
  