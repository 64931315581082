import {
  CONSTANTS_GET_REQUEST, CONSTANTS_GET_SUCCESS, CONSTANTS_GET_FAIL
} from './AppActions';
import {USER_LOGOUT_SUCCESS} from '../Auth/AuthActions';

const initialState={
  constants_loading:false,
  constants_loaded:false,
  constants:{}
};

export default (state=initialState,action)=>{
  switch (action.type){
    case CONSTANTS_GET_REQUEST:
      return Object.assign({},state,{
        constants_loading:true,
        constants_loaded:false,
        constants:{}
      });
    case CONSTANTS_GET_SUCCESS:
      return Object.assign({},state,{
        constants_loading:false,
        constants_loaded:true,
        constants:{...action.response.constants}
      });
    case CONSTANTS_GET_FAIL:
      return Object.assign({},state,{
        constants_loading:false,
        constants_loaded:false,
        constants:{}
      });
    case USER_LOGOUT_SUCCESS:
      return Object.assign({},initialState);
    default:
      return state;
  }
}