import React from 'react';
import { Field, reduxForm } from 'redux-form';
// import FieldInput from '../FormComponents/FieldInput';
// import { Button, Form } from 'react-bootstrap';
import { FormGroup, ControlLabel, FormControl, HelpBlock, Button, Form } from 'react-bootstrap';

const FieldInput = ({ input, meta, type, placeholder, min, max, name, glyph, error, id, label }) => {
  var validationState=null,help=null;
  if (meta.touched&&meta.error){
    validationState="warning";
    help=(
      <HelpBlock>{meta.error}</HelpBlock>
    )
  }
 
  return (
    <FormGroup validationState={validationState}>
      <ControlLabel>{label}</ControlLabel>
      <FormControl
        type={type}
        name={name||null}
        placeholder={placeholder}
        min={min}
        max={max}
        value={input.value}
        onChange={input.onChange} />
      {help}
    </FormGroup>
)
}

const required = value => (value === undefined ? 'Required' : undefined)
const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined
const minLength3 = minLength(3);
const minLength8 = minLength(8);

const matchPass = (value,allValues) => {
    return allValues.new_password&&allValues.new_password!==value ? 'Must match the password provided' : undefined
}
 
const LoginForm=props=>{
  return (
    <Form horizontal onSubmit={props.handleSubmit(props.formSubmit)}>
      <Field
        id="login-username"
        label="User ID" 
        type="text"
        className="form-control"
        glyph="envelope"
        name="userid"
        placeholder="Enter user ID"
        validate={[required,minLength3]}
        component={FieldInput}
        />
      <Field
        id="login-password"
        label="Password" 
        type="password"
        className="form-control"
        glyph="key"
        name="password"
        placeholder="Enter password"
        validate={[required]}
        component={FieldInput}
        />
      {props.password_expired&&[
      <Field 
        key="login-newpass1"
        id="login-new_password" 
        label="New password" 
        type="password" 
        className="form-control" 
        glyph="key" 
        name="new_password" 
        placeholder="Enter new password" 
        validate={[required,minLength8]} 
        component={FieldInput}
        />,
      <Field 
        key="login-newpass2"
        id="login-new_repassword" 
        label="Confirm new password" 
        type="password" 
        className="form-control" 
        glyph="key" 
        name="new_repassword" 
        placeholder="Repeat new password" 
        validate={[matchPass]} 
        component={FieldInput}
        />
      ]}
      <Button type="submit" bsStyle="primary" block>Login</Button>      
    </Form>
  );
}

export default reduxForm({
  form:'login'
})(LoginForm);