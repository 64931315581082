import React, { Component } from 'react';
//import ProductForm from './ProductForm.js';
import { Row, Col, Panel, Alert, Button, Grid } from 'react-bootstrap';
import WorkSheetForm from './WorkSheetForm';
import WorkSheetEditor from './WorkSheetEditor';
import moment from 'moment';

class WorkSheet extends Component{
  
  componentDidMount(){
    this.props.getWorkSheet(this.props.params.id);
  }

  submitForm(form){
    var ws={
      ...form,
      month:+moment(form.month).format('YYYYMM'),
      bonus_rate:this.props.bonus_rate
    };
    this.props.setWorkSheet(ws);
  }

  render(){
    const date={month:moment().startOf('month').toDate()};
    return (
      <Grid fluid>
        <Row>
          {(this.props.params.id&&(this.props.params.id!=="new"))?
            <Col xs={12}>
              <WorkSheetEditor 
                initialValues={this.props.initialValues}
                auth={this.props.auth}
                back={this.props.goBack} 
                deleteWorkItem={this.props.deleteWorkItem.bind(this,this.props.params.id)}
                onSubmit={this.props.setWorkSheet.bind(this)}
              />
            </Col>
            : 
              <Col xs={12} sm={6} smOffset={3}>
                <Panel>
                  <Col xs={12}>
                    <WorkSheetForm onSubmit={this.submitForm.bind(this)} initialValues={date}/>
                    {this.props.product_failed&&
                      <Row>
                        <Alert bsStyle='danger'>
                          Worksheet could not be loaded!
                        </Alert>
                      </Row>
                    }
                    <Button className="pull-right" onClick={this.props.goBack}>Back</Button>
                  </Col>
                </Panel>
              </Col>
          }
        </Row>
      </Grid>
    )
  }
}

export default WorkSheet;