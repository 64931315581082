import {CALL_API} from '../middleware/api';
import { goBack } from 'react-router-redux';

export const WORKSHEETS_GET_REQUEST   ='WORKSHEETS_GET_REQUEST';
export const WORKSHEETS_GET_SUCCESS   ='WORKSHEETS_GET_SUCCESS';
export const WORKSHEETS_GET_FAIL      ='WORKSHEETS_GET_FAIL';
export const WORKSHEETS_PDF_REQUEST   ='WORKSHEETS_PDF_REQUEST';
export const WORKSHEETS_PDF_SUCCESS   ='WORKSHEETS_PDF_SUCCESS';
export const WORKSHEETS_PDF_FAIL      ='WORKSHEETS_PDF_FAIL';
export const WORKSHEET_GET_REQUEST	  ='WORKSHEET_GET_REQUEST';
export const WORKSHEET_GET_SUCCESS	  ='WORKSHEET_GET_SUCCESS';
export const WORKSHEET_GET_FAIL   	  ='WORKSHEET_GET_FAIL';
export const WORKSHEET_SET_REQUEST	  ='WORKSHEET_SET_REQUEST';
export const WORKSHEET_SET_SUCCESS	  ='WORKSHEET_SET_SUCCESS';
export const WORKSHEET_SET_FAIL   	  ='WORKSHEET_SET_FAIL';
export const WORKSHEET_DELETE_REQUEST	='WORKSHEET_DELETE_REQUEST';
export const WORKSHEET_DELETE_SUCCESS	='WORKSHEET_DELETE_SUCCESS';
export const WORKSHEET_DELETE_FAIL   	='WORKSHEET_DELETE_FAIL';
export const WORKSHEET_SET_STATE      ='WORKSHEET_SET_STATE';


export function setWorkSheetState(worksheet){
  return {
    type:WORKSHEET_SET_STATE,
    worksheet
  }
}

export function getWorkSheets(opts){
  return {
    [CALL_API]:{
      endpoint:'/worksheets',
      params:opts,
      authenticated:true,
      actions:[WORKSHEETS_GET_REQUEST, WORKSHEETS_GET_SUCCESS, WORKSHEETS_GET_FAIL]
    }
  }
}

export function getWorkSheetsPDF(opts){
  return {
    [CALL_API]:{
      endpoint:'/reports/worksheets',
      params:opts,
      mimetype: 'application/pdf',
      authenticated:true,
      actions:[WORKSHEETS_PDF_REQUEST, WORKSHEETS_PDF_SUCCESS, WORKSHEETS_PDF_FAIL]
    }
  }
}

export function getWorkSheet(id){
  if (id==="new") return {
    type: WORKSHEET_GET_SUCCESS,
    response:{
      success:true,
      worksheet:{}
    }
   };
  else return {
    [CALL_API]:{
      endpoint:'/worksheets/'+id,
      authenticated:true,
      actions:[WORKSHEET_GET_REQUEST, WORKSHEET_GET_SUCCESS, WORKSHEET_GET_FAIL]
    }
  };
}

export function setWorkSheet(ws){
  const n = !!ws._id;
  return {
    [CALL_API]:{
      endpoint:n?('/worksheets/'+ws._id):'/worksheets',
      method:n?'PUT':'POST',
      payload:ws,
      authenticated:true,
      actions:[
        WORKSHEET_SET_REQUEST, 
        {
          type:WORKSHEET_SET_SUCCESS,
//          route:n?undefined:'/worksheets',
          action:n ? getWorkSheet(ws._id):goBack(),
          notif:{
            message:(n?'Saved':'Created')+' work log',
            kind:'info',
            dismissAfter:2000
          }
        },
        WORKSHEET_SET_FAIL
      ]
    }
  }
}

export function deleteWorkSheet(id){
  return {
    [CALL_API]:{
      endpoint:'/worksheets/'+id,
      method:'DELETE',
      authenticated:true,
      message:'Deleted worksheet '+id,
      actions:[
        WORKSHEET_DELETE_REQUEST, 
        {
          type:WORKSHEET_DELETE_SUCCESS,
      //    action:getWorkSheets(),
          notif:{
            message:'Deleted worksheet '+id,
            kind:'success',
            dismissAfter:2000
          }
        },
        WORKSHEET_DELETE_FAIL
      ]
    }
  }
}

