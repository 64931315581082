import Landing from './Landing';
import { connect } from 'react-redux';
import { loginUser } from '../Auth/AuthActions';

const mapStateToProps=state=>{
  return {
    auth_failed:state.auth.auth_failed,
    password_expired:state.auth.password_expired,
    message:state.auth.message
  }
}

const mapDispatchToProps=dispatch=>{
  return {
    authenticate:(redirect,form)=>{dispatch(loginUser(form,redirect))}
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Landing);