import User from './User';
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';
import { getUser, setUser } from './UsersActions';
import { getProducts } from '../Products/ProductsActions';

const mapStateToProps=(state)=>{
  return {
    initialValues:state.users.user,
    user_saved:state.users.user_saved,
    departments:[...new Set((state.products.items||[]).map(i=>i.department))],
    auth:state.auth.user
  }
}

const mapDispatchToProps=(dispatch)=>{
  return {
    getProducts(){dispatch(getProducts())},
    getUser(id){dispatch(getUser(id))},
    setUser(user){dispatch(setUser(user))},
    goBack(){dispatch(goBack())}
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(User);