import React, { Component } from 'react';
import Header from './Header.js';
import { Grid, Col, Row } from 'react-bootstrap';
import {Notifs} from 'redux-notifications';
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper';
import 'redux-notifications/lib/styles.css';
import './notifs.css';

import 'react-widgets/dist/css/react-widgets.css';

const visibleOnlyAuth=connectedAuthWrapper({
  authenticatedSelector:state=>!!state.auth.authenticated,
  wrapperDisplayName:'VisibleOnlyAuthenticated',
  FailureComponent:()=>null
});

const Nav=visibleOnlyAuth((props)=><Header user={props.user} logout={props.logout}/>);

class App extends Component{
  render(){
    return (
      <div>
        <Nav user={this.props.user} logout={this.props.logout} />
        <Grid fluid={true}>
          <Col xs={12} sm={10} smOffset={1} md={10} mdOffset={1} lg={10} lgOffset={1} className="main-div">
            <Row>
              <Col xs={12}>
                {this.props.children}
              </Col>
            </Row>
          </Col>
        </Grid>
        <Notifs /> 
      </div>
    )
  }
}

export default App;
