import React from 'react';
import { Well, Table, Row, Col, FormGroup, ControlLabel, Modal, Button, FormControl, Grid } from 'react-bootstrap';
import {DropdownList} from 'react-widgets';
import IconButton from '../FormComponents/IconButton';
import moment from 'moment-timezone';
import './WorkSheetEditor.css';

const Verified = props=>{
  let {auth,verified,onVerify}=props;
  if (auth.type==='admin' ||auth.type==='supervisor'){
    return verified?<Button bsStyle="success" onClick={v=>onVerify(false)}>Yes</Button>:
                    <Button bsStyle="warning" onClick={v=>onVerify(true)}>No</Button>;
  } else {
    return verified?<FormControl.Static bsStyle="success">Yes</FormControl.Static>:
                    <FormControl.Static bsStyle="warning">No</FormControl.Static>;
  }
}

const Bonus = props=>{
  let {auth,bonus_rate,onChange}=props;
  if (auth.type==='admin' ||auth.type==='supervisor'){
    return <FormGroup>
        <ControlLabel>Bonus Rate</ControlLabel>
        <DropdownList
            data={[{l:'0%',v:0},{l:'0.5%',v:.005},{l:'1%',v:.01},{l:'1.5%',v:.015},{l:'2%',v:.02},{l:'2.5%',v:.025},{l:'3%',v:.03},{l:'3.5%',v:.035},{l:'4%',v:.04},{l:'4.5%',v:.045},{l:'5%',v:.05},{l:'5.5%',v:.055},{l:'6%',v:.06},{l:'6.5%',v:.065},{l:'7%',v:.07},{l:'7.5%',v:.075},{l:'8%',v:.08},{l:'8.5%',v:.085},{l:'9%',v:.09},{l:'9.5%',v:.095},{l:'10%',v:.1},{l:'10.5%',v:.105},{l:'11%',v:.11}]}
            value={bonus_rate}
            textField='l'
            valueField='v'
            onChange={onChange}
            />
      </FormGroup>
  } else {
    return <FormGroup>
      <ControlLabel>Bonus Rate</ControlLabel>
      <FormControl.Static>{(100*bonus_rate).toFixed()+'%'}</FormControl.Static>
    </FormGroup>
  }
}


class WorkSheetEditor extends React.Component {
  constructor(props){
    super(props);
    this.state={
      deleteModal:false,
      modalMessage:"",
      modalCallback:()=>false
    }
  }

  handleVerify(verified){  
    let ws={
      _id:this.props.initialValues._id,
      verified_by:this.props.auth._id,
      verified
    };
    this.props.onSubmit(ws);
  }

  handleBonus(o){  
    let ws={
      _id:this.props.initialValues._id,
      bonus_rate:o.v
    };
    this.props.onSubmit(ws);
  }

  render(){
    const closeModal = () => { this.setState({ deleteModal: false }) }
    if (!this.props.initialValues) return (<p>Loading...</p>);
    let { month, _id, user, items, bonus_rate, bonus, sum, sum_total, verified } = this.props.initialValues;
    items.sort((a,b)=>a.date<b.date?-1:1);
    let days = items.map(i => ({ date: moment(i.date).tz('Europe/Riga').format('YYYY-MM-DD'), time: i.working_time.name }))
    let days_sum = {};
    days.forEach(d=>{
      days_sum[d.time]=((days_sum[d.time]||new Set()).add(d.date))
    })
    let day_count = (new Set(days.map(i=>i.date))).size;
    return (
      <Grid fluid>
        <Col className="page-header">
        <Row>
          <Col xs={10} sm={6}><h1>Work Sheet</h1><h3>{moment(month+'01').format('MMMM YYYY')}</h3></Col>
          {!verified&&<Col xs={2} sm={6}><IconButton type="create" redirect={`/worksheets/${_id}/items/new`} right>Create</IconButton></Col>} 
        </Row>
        <Row>
          <Col xs={12} sm={4} md={2} >
            <FormGroup>
              <ControlLabel>User</ControlLabel>
              <FormControl.Static>{user.name} ({user.userid})</FormControl.Static>
            </FormGroup>
          </Col>
          <Col xs={12} sm={4} md={2} >
            <FormGroup>
              <ControlLabel>Verified</ControlLabel><br/>
              <Verified auth={this.props.auth} verified={verified} onVerify={this.handleVerify.bind(this)}/>
            </FormGroup>
          </Col>
          <Col xs={12} sm={4} md={2} >
            <Bonus auth={this.props.auth} bonus_rate={bonus_rate} onChange={this.handleBonus.bind(this)}/>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={4} md={2} >
            <FormGroup> 
              <ControlLabel>Days worked</ControlLabel>
              <FormControl.Static>{day_count}</FormControl.Static>
            </FormGroup>
          </Col>
          {Object.keys(days_sum).map(k=>(
          <Col xs={12} sm={4} md={2} key={`working_time_${k}`}>
            <FormGroup> 
              <ControlLabel>{k}</ControlLabel>
              <FormControl.Static>{days_sum[k].size}</FormControl.Static>
            </FormGroup>
          </Col>
          ))}
        </Row>
        </Col>
        
        <Col className="tbl-cards" smHidden mdHidden lgHidden>
        {items.map(work_item=>{
         var prod=work_item.product||{};
         return(
          <Well key={work_item._id} bsSize='sm'>
              <Row>
                <Col xs={4}><strong>Date</strong></Col>
                <Col xs={8}>{moment(work_item.date).tz('Europe/Riga').format('YYYY-MM-DD')}</Col>
              </Row>
              <Row>
                <Col xs={4}><strong>Operation</strong></Col>
                <Col xs={8}>{prod.op_code} {prod.op_desc}</Col>
              </Row>
              <Row>
                <Col xs={4}><strong>Product</strong></Col>
                <Col xs={8}>{prod.prod_code} {prod.prod_desc}</Col>
              </Row>
              <Row>
                <Col xs={4}><strong>Department</strong></Col>
                <Col xs={8}>{prod.department}</Col>
              </Row>
              <Row>
                <Col xs={4}><strong>Order# / Work</strong></Col>
                <Col xs={8}>{work_item.order_number}</Col>
              </Row>
              <Row>
                <Col xs={4}><strong>Pieces / Hours</strong></Col>
                <Col xs={8}>{work_item.pieces}</Col>
              </Row>
              <Row>
                <Col xs={4}><strong>Working time</strong></Col>
                <Col xs={8}>{work_item.working_time.name}</Col>
              </Row>
              <Row>
                <Col xs={4}><strong>Salary / Piece</strong></Col>
                <Col xs={8}>{prod.cost_eur.toFixed(5)}</Col>
              </Row>
              <Row>
                <Col xs={4}><strong>Total</strong></Col>
                <Col xs={8}>{work_item.sum_total.toFixed(2)}</Col>
              </Row>
            {!verified&&<Row>
              <Col xs={2} xsOffset={7}>
                <IconButton type="edit" redirect={`/worksheets/${_id}/items/${work_item._id}`}>Edit</IconButton>
              </Col>
              <Col xs={2}>
                <IconButton type="delete" onClick={()=>{
                  this.setState({
                    deleteModal:true,
                    modalMessage:`Are you sure you want to delete ${work_item.pieces} x ${work_item.product.op_code}? This action is permanent.`,
                    modalCallback:()=>{this.props.deleteWorkItem(work_item._id);closeModal();}
                  });
                }}>Delete</IconButton>
              </Col>
              </Row>
            }
          </Well>
          )
        })
        }
        </Col>
        <Col className="tbl-normal" xsHidden>
        <Table className="crud-table" responsive>
          <thead>
            <tr>
              <th className="col-sm-1">Date</th>
              <th className="col-sm-1 hidden-lg">Operation</th>
              <th className="col-sm-1 hidden-sm hidden-md">Operation</th>
              <th className="col-sm-1">Product Code</th>
              <th className="col-sm-1 hidden-sm hidden-md">Product Description</th>
              <th className="col-sm-1 hidden-sm hidden-md">Department</th>
              <th className="col-sm-1 hidden-sm hidden-md">Order# / Work</th>
              <th className="col-sm-1">Pieces / Hours</th>
              <th className="col-sm-1">Working time</th>
              <th className="col-sm-1 hidden-sm hidden-md">Salary / Piece</th>
              <th className="col-sm-1">Total</th>
              {!verified&&<th className="col-sm-2 col-md-1 tbl-ctrls"></th>}
            </tr>
          </thead>
          <tbody>
            {items.map(work_item=>{
              var prod=work_item.product||{};
              return(
              <tr key={work_item._id}>
                <td>{moment(work_item.date).tz('Europe/Riga').format('YYYY-MM-DD')}</td>
                <td className="hidden-lg">{prod.op_code}</td>
                <td className="hidden-sm hidden-md">{prod.op_code+' '+prod.op_desc}</td>
                <td>{prod.prod_code}</td>
                <td className="hidden-sm hidden-md">{prod.prod_desc}</td>
                <td className="hidden-sm hidden-md">{prod.department}</td>
                <td className="hidden-sm hidden-md">{work_item.order_number}</td>
                <td>{work_item.pieces}</td>
                <td>{work_item.working_time.name}</td>
                <td className="hidden-sm hidden-md">{prod.cost_eur.toFixed(5)}</td>
                <td>{work_item.sum_total.toFixed(2)}</td>
                {!verified&&<td className="tbl-ctrls">
                  <IconButton type="delete" onClick={()=>{
                    this.setState({
                      deleteModal:true,
                      modalMessage:`Are you sure you want to delete ${work_item.pieces} x ${work_item.product.op_code}? This action is permanent.`,
                      modalCallback:()=>{this.props.deleteWorkItem(work_item._id);closeModal();}
                    });
                  }}>Delete</IconButton>
                  <IconButton type="edit" redirect={`/worksheets/${_id}/items/${work_item._id}`}>Edit</IconButton>
                </td>
                }
              </tr>
            )
            })}
          </tbody>
        </Table>
        </Col>
        <Modal show={this.state.deleteModal} onHide={closeModal}>
          <Modal.Header>Confirm delete...</Modal.Header>
          <Modal.Body>{this.state.modalMessage}</Modal.Body>
          <Modal.Footer>
            <Button onClick={closeModal}>Cancel</Button>
            <Button onClick={this.state.modalCallback}>Confirm</Button>
          </Modal.Footer>
        </Modal>
        <Row>
            <Col xs={4} ><h4>Sum: €{sum.toFixed(2)}</h4></Col> 
            <Col xs={4} ><h4>Bonus: €{bonus.toFixed(2)}</h4></Col> 
            <Col xs={4} ><h4>Total: €{sum_total.toFixed(2)}</h4></Col> 
        </Row>
      </Grid>
    );
  }
}

export default WorkSheetEditor;