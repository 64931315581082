import {
  PRODUCTS_GET_REQUEST, PRODUCTS_GET_SUCCESS, PRODUCTS_GET_FAIL,
  PRODUCT_GET_REQUEST, PRODUCT_GET_SUCCESS, PRODUCT_GET_FAIL,
  PRODUCT_SET_REQUEST, PRODUCT_SET_SUCCESS, PRODUCT_SET_FAIL,
  PRODUCT_DELETE_REQUEST, PRODUCT_DELETE_SUCCESS, PRODUCT_DELETE_FAIL
} from './ProductsActions';
import {USER_LOGOUT_SUCCESS} from '../Auth/AuthActions';

const initialState={
  products_loading:false,
  products_loaded:false,
  items:[],
  product_loading:false,
  product_loaded:false,
  product_deleting:false,
  product_deleted:false,
  product:null,
  filters:{}
};

export default (state=initialState,action)=>{
  switch (action.type){
    case PRODUCTS_GET_REQUEST:
      return Object.assign({},state,{
        products_loading:true,
        products_loaded:false,
        product:null,
        items:[],
        filters:{}
      });
    case PRODUCTS_GET_SUCCESS:
      return Object.assign({},state,{
        products_loading:false,
        products_loaded:true,
        product:null,
        items:[...action.response.products]
      });
    case PRODUCTS_GET_FAIL:
      return Object.assign({},state,{
        products_loading:false,
        products_loaded:false,
        product:null,
        items:[]
      });
      case PRODUCT_GET_REQUEST:
      return Object.assign({},state,{
        product_loading:true,
        product_loaded:false,
        product:null
      });
    case PRODUCT_GET_SUCCESS:
      return Object.assign({},state,{
        product_loading:false,
        product_loaded:true,
        product:{...action.response.product}
      });
    case PRODUCT_GET_FAIL:
      return Object.assign({},state,{
        product_loading:false,
        product_loaded:false,
        product:null
      });
      case PRODUCT_SET_REQUEST:
      return Object.assign({},state,{
        product_saving:true,
        product_saved:false
      });
    case PRODUCT_SET_SUCCESS:
      return Object.assign({},state,{
        product_saving:false,
        product_saved:true
      });
    case PRODUCT_SET_FAIL:
      return Object.assign({},state,{
        product_saving:false,
        product_saved:false
      });
      case PRODUCT_DELETE_REQUEST:
      return Object.assign({},state,{
        product_deleting:true,
        product_deleted:false
      });
    case PRODUCT_DELETE_SUCCESS:
      return Object.assign({},state,{
        product_deleting:false,
        product_deleted:true
      });
    case PRODUCT_DELETE_FAIL:
      return Object.assign({},state,{
        product_deleting:false,
        product_deleted:false
      });
    case USER_LOGOUT_SUCCESS:
      return Object.assign({},initialState);
    default:
      return state;
  }
}