import WorkItem from './WorkItem';
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';
import { getWorkItem, setWorkItem } from './WorkItemActions';
import { getWorkSheet } from '../WorkSheets/WorkSheetsActions';
import { getProducts } from '../Products/ProductsActions';
import {logoutAndRedirect} from '../Auth/AuthActions';

const mapStateToProps=(state)=>{
  return {
    initialValues:state.workitem.workitem,
    error:state.workitem.error,
    workitem_saved:state.workitem.workitem_saved,
    products_loaded:state.products.products_loaded,
    working_time:state.app.constants.working_time,
    products:state.products.items,
    worksheet:state.worksheets.worksheet,
    worksheets:state.worksheets.items
  }
}

const mapDispatchToProps=(dispatch)=>{
  return {
    getWorkSheet:(worksheet_id)=>{dispatch(getWorkSheet(worksheet_id))},
    getWorkItem:(worksheet_id,id)=>{dispatch(getWorkItem(worksheet_id,id))},
    setWorkItem:(worksheet_id,wi)=>{dispatch(setWorkItem(worksheet_id,wi))},
    getProducts:()=>{dispatch(getProducts())},
    logout:()=>{dispatch(logoutAndRedirect())},
    goBack:()=>{dispatch(goBack())}
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(WorkItem);