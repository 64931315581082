import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as notifReducer } from 'redux-notifications';
import AppReducer from './App/AppReducers';
import AuthReducer from './Auth/AuthReducers';
import UsersReducer from './Users/UsersReducers';
import ProductsReducer from './Products/ProductsReducers';
import ReportsReducer from './Reports/ReportsReducers';
import WorkSheetsReducer from './WorkSheets/WorkSheetsReducers';
import WorkItemReducer from './WorkItem/WorkItemReducers';

export default combineReducers({
  routing:routerReducer,
  form:formReducer,
  notifs:notifReducer,
  app:AppReducer,
  auth:AuthReducer,
  users:UsersReducer,
  products:ProductsReducer,
  reports:ReportsReducer,
  worksheets:WorkSheetsReducer,
  workitem:WorkItemReducer
});