import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { FormGroup, Button, Form } from 'react-bootstrap';
import FieldInput from '../FormComponents/FieldInput';
import FieldStatic from '../FormComponents/FieldStatic';

const required = value => (value === undefined ? 'Required' : undefined)
const requiredIfNew = (value,allValues )=>(allValues._id||value!==undefined ? undefined : 'Required')
const matchPass = (value,allValues) => {
   return allValues.password&&allValues.password!==value ? 'Must match the password provided' : undefined
}
const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined
const minLength3 = minLength(3)

const ProfileForm = props=>{
  const { handleSubmit, pristine, submitting } = props;
  return (
    <Form horizontal onSubmit={handleSubmit}>
      <Field 
        id="user-id" 
        name="userid" 
        label="User ID" 
        type="text" 
        component={FieldStatic}
        />
      <Field 
        id="user-name" 
        name="name" 
        label="Name" 
        type="text" 
        component={FieldStatic}
        />
      <Field 
        id="user-dep" 
        name="department" 
        label="Position"
        type="text" 
        validate={[required]} 
        component={FieldStatic} 
        />
      <Field
        id="user-type" 
        name="type"
        label="User type"
        type="text" 
        component={FieldStatic} 
        />  
      <Field 
        id="user-password" 
        name="password" 
        label="Password" 
        type="password" 
        className="form-control" 
        glyph="key" 
        placeholder="Password" 
        validate={[requiredIfNew,minLength3]} 
        component={FieldInput}
        />
      <Field 
        id="user-repassword" 
        name="repassword" 
        label="Confirm password" 
        type="password" 
        className="form-control" 
        glyph="key" 
        placeholder="Password" 
        validate={[matchPass]} 
        component={FieldInput}
        /> 
      <FormGroup className="form-actions">
        <Button type="submit" bsStyle="primary" disabled={pristine || submitting}>Change Password</Button>
      </FormGroup>
    </Form>
  );
}

export default reduxForm({
  form:'profileForm'
})(ProfileForm);