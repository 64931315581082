import {
  REPORT_GET_REQUEST, REPORT_GET_SUCCESS, REPORT_GET_FAIL
} from './ReportsActions';
import {USER_LOGOUT_SUCCESS} from '../Auth/AuthActions';

const initialState={
  report_loading:false,
  report_loaded:false,
  report:null
};

export default (state=initialState,action)=>{
  switch (action.type){
    case REPORT_GET_REQUEST:
      return Object.assign({},state,{
        report_loading:true,
        report_loaded:false,
        report:null
      });
    case REPORT_GET_SUCCESS:
      return Object.assign({},state,{
        report_loading:false,
        report_loaded:true,
        report:action.response.report
      });
    case REPORT_GET_FAIL:
      return Object.assign({},state,{
        report_loading:false,
        report_loaded:false,
        report:null
      });
    case USER_LOGOUT_SUCCESS:
      return Object.assign({},initialState);
    default:
      return state;
  }
}