import Products from './Products.js';
import { connect } from 'react-redux';
import { getProducts, deleteProduct } from './ProductsActions';

const mapStateToProps=(state)=>{
  return {
    products_loaded:state.products.products_loaded,
    products:state.products.items,
    auth:state.auth.user
  }
}

const mapDispatchToProps=(dispatch)=>{
  return {
    getProducts:()=>{dispatch(getProducts())},
    deleteProduct:(id)=>{dispatch(deleteProduct(id))}
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Products);