import React, {Component} from 'react';
import AvatarEditor from 'react-avatar-editor';
import { ControlLabel, FormGroup, Col, Button } from 'react-bootstrap';

class Avatar extends Component{
  constructor(props){
    super(props);
    this.state={
      scale:1.0,
      changed:false
    };
  }
  onSave = () => {
    if (this.editor) {
      const canvasScaled = this.editor.getImageScaledToCanvas()
      this.props.onChange(canvasScaled.toDataURL());
    }
  }
  setEditorRef = (editor) => this.editor = editor;
  
  render(){
    return (
    <div>
      <p><input type="file" onChange={e=>this.setState({image:e.target.files[0]})} /></p>
      <AvatarEditor
        ref={this.setEditorRef}
        image={this.state.image||this.props.value||"/img/profile.jpg"}
        width={200}
        height={200}
        border={20}
        borderRadius={5}
        scale={this.state.scale}
        onImageReady={e=>this.setState({changed:false})}
        onImageChange={e=>this.setState({changed:true})}
        onPositionChange={e=>this.setState({changed:true})}
        />
      <input type="range" 
        min="0.1" 
        max="2" 
        step="0.01" 
        width={200}
        value={this.state.scale}
        onChange={e=>this.setState({scale:+e.target.value})}
        />
      <Button onClick={this.onSave} disabled={!this.state.changed}>Set picture</Button>
      </div>
    )
  }

}

const AvatarInput = ({ input, meta, type, placeholder, min, max, name, glyph, error, id, label }) => {
 

  return (
    <FormGroup>
      <Col componentClass={ControlLabel} sm={4}>{label}</Col>
      <Col sm={8}>
        <Avatar {...input} />
      </Col>
    </FormGroup>  
  )

}

export default AvatarInput