import React, { Component } from 'react';
import { Table, Button, Row, Col } from 'react-bootstrap';
import FileSaver from 'file-saver';

class Reports extends Component{
  constructor(props){
    super(props);
    this.props.getReport(props.params.type,props.location.search);
    this.state={
      filter:[]
    }
  }
  downloadCSV(header,data,filename){
    const csv = [header,...data].map(r=>r.join('\t')).join('\n');
    const buf = new ArrayBuffer(csv.length*2);
    let bufView = new Uint16Array(buf);
    for (let i=0, len=csv.length; i< len; i++) {
      bufView[i] = csv.charCodeAt(i);
    }
    const b = new Blob([Uint16Array.from([0xfeff]), bufView], { type: 'text/csv;charset=UTF-16LE;' });
    FileSaver.saveAs(b,filename||'SLTIC_report.csv', { autoBom: false });
  }

  render(){
    if (this.props.report_loaded){
      const {title, header, header_groups, data, footer, filename} = this.props.report;
      let sums = [];
      const pc = /^Post costing/.test(title);
      return (
        <div id="reports">
          <h1>{title||'Report'}</h1>
          <Row>
            <Col xs={12} sm={3}>
              <input className="form-control" type="text" placeholder="Filter" onChange={v=>{this.setState({filter:v.target.value.toLowerCase().split(" ")})}}/>
            </Col>
            <Col xs={12} sm={3}>
              <Button onClick={() => this.downloadCSV(header,data,filename)}>Download CSV</Button>
            </Col>
          </Row>
          <Table className="crud-table" responsive>
            <thead>
            {header_groups&&
              <tr>{header_groups.map((h,i)=><th key={"thg"+i} colSpan={h.span}>{h.label}</th>)}</tr>
            }
              <tr>
                {header.map((h,i)=><th key={"th"+i}>{h}</th>)}
              </tr>
            </thead>
            <tbody>
            {data.map((row,ri)=>{
              let search_string = row.toString().toLowerCase();
              let match = true;
              this.state.filter.forEach(fi=>{
                match = match && (search_string.indexOf(fi)>-1);
              });
              if (!match)return null;
              row.forEach((c,ci) => {
                sums[ci] = (sums[ci] || 0) + parseFloat(c);
              });
              return (<tr key={'tbody-tr'+ri}>
                {row.map((c,ci) => <td key={'td-'+ri+'-'+ci}>{c}</td>)}
                </tr>);
              })
            }
            </tbody>
            {footer&&
            <tfoot>
              {this.state.filter.length > 0 &&
              <tr>
                {sums.map((h,i)=><th key={"th"+i}>{ 
                i === 0 ? 'Filtered sum:' 
                       : !footer[i] ? '' 
                       : pc && i === 16 ? (100 * parseFloat(sums[15]) / parseFloat(sums[14])).toFixed() 
                       : h.toFixed(2)
                }</th>)}
              </tr>
              }
              <tr>
                {footer.map((h,i)=><th key={"th"+i}>{h}</th>)}
              </tr>
            </tfoot>
            }
          </Table>
        </div>
      );
    }
    else {
      return (
        <div className="loading">
          Loading...
        </div>
      );
    }
  }
}

export default Reports;