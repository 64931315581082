import React from 'react';
import { FormGroup, ControlLabel, HelpBlock, Col } from 'react-bootstrap';
import { SelectList } from 'react-widgets';

const SelectListInput = ({ input, meta, label, data, valueField, textField, filter, groupBy, itemComponent, valueComponent }) =>{
  var validationState=null,help=null;

  if (meta.touched&&meta.error){
    validationState="warning";
    help=(
      <HelpBlock>{meta.error}</HelpBlock>
    )
  }
  return (
    <FormGroup validationState={validationState}>
      <Col componentClass={ControlLabel} sm={4}>{label}</Col>
      {/* <ControlLabel>{label}</ControlLabel> */}
      <Col sm={8}>
      <SelectList {...input}
        data={data} 
        textField={textField}
        valueField={valueField}
        onBlur={()=>input.onBlur()}
        />
      {help}
      </Col>
    </FormGroup>
  )
}

export default SelectListInput;
  