import {CALL_API} from '../middleware/api';

export const CONSTANTS_GET_REQUEST   ='CONSTANTS_GET_REQUEST';
export const CONSTANTS_GET_SUCCESS   ='CONSTANTS_GET_SUCCESS';
export const CONSTANTS_GET_FAIL      ='CONSTANTS_GET_FAIL';

export function getConstants(){
  return {
    [CALL_API]:{
      endpoint:'/constants',
      authenticated:true,
      actions:[CONSTANTS_GET_REQUEST, CONSTANTS_GET_SUCCESS, CONSTANTS_GET_FAIL]
    }
  }
}
