import Profile from './Profile';
import { connect } from 'react-redux';
import { getUser, setUser } from '../Users/UsersActions';

const mapStateToProps=state=>{
  return {
    auth:state.auth.user,
    name:state.auth.user.name,
    userid:state.auth.user.userid,
    department:state.auth.user.department,
    type:state.auth.user.type,
    user:state.users.user,
    user_saved:state.users.user_saved  
  }
}

const mapDispatchToProps=dispatch=>{
  return {
    getUser(id){dispatch(getUser(id))},
    setUser(user){dispatch(setUser(user))},
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Profile);