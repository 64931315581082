import fetchPonyfill from 'fetch-ponyfill';
import { push } from 'react-router-redux';
import { getConstants } from '../App/AppActions';
import cfg from '../config.js'
const { fetch } = fetchPonyfill();

export const USER_LOGIN_REQUEST	='USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS	='USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL   	='USER_LOGIN_FAIL';
export const USER_LOGOUT_REQUEST	='USER_LOGOUT_REQUEST';
export const USER_LOGOUT_SUCCESS	='USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_FAIL   	='USER_LOGOUT_FAIL';


export function checkHttpStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response.json();
  }
  var error = new Error(response.statusText)
  error.response = response
  throw error;
}

function loginSuccess(user){
  return {
    type: USER_LOGIN_SUCCESS,
    user
  }
}

export function loginUserRequest() {
  return {
    type: USER_LOGIN_REQUEST
  }
}

export function loginUserSuccess(response) {
  return function(dispatch){
    if (response.token) localStorage.setItem('token', response.token);
//    dispatch(fetchUser(token));
    dispatch(loginSuccess(response.user));
    dispatch(getConstants());    
//    dispatch(fetchStats(token));
  }
}

export function loginUserFailure(msg,password_expired) {
  localStorage.removeItem('token');
  return {
    type: USER_LOGIN_FAIL,
    message: msg,
    password_expired
  }
}

export function logout() {
  localStorage.removeItem('token');
  return {
    type: USER_LOGOUT_SUCCESS
  }
}

export function logoutAndRedirect() {
  return (dispatch, state) => {
    dispatch(logout());
    dispatch(push('/'));
  }
}

export function loginUser(form, redirect="/") {
  return function(dispatch) {
    dispatch(loginUserRequest());
    let token = localStorage.getItem('token') || null;
    let p;
    if (form) {
      p=fetch(cfg.API_SERVER+'/auth/login',{
        method: 'post',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
          body: JSON.stringify({userid: form.userid, password: form.password, new_password: form.new_password})
      });
    } else if(token) {
      p=fetch(cfg.API_SERVER+'/users/me',{
        method: 'get',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
    } else {
      dispatch(loginUserFailure("No credentials provided."));
    }
    return p.then(checkHttpStatus)
      .then(response => {
        if (response.success===true){
            dispatch(loginUserSuccess(response));
//            dispatch(push(redirect));
//            dispatch(goBack());
        }
        else {
            dispatch(loginUserFailure(response.message,response.password_expired))
        }
      })
      .catch(error => dispatch(loginUserFailure(error.message)))
      
  }
}