import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { FormGroup, Button, Form } from 'react-bootstrap';
import FieldInput from '../FormComponents/FieldInput';
import FieldStatic from '../FormComponents/FieldStatic';
import SelectListInput from '../FormComponents/SelectListInput';
import DropdownListInput from '../FormComponents/DropdownListInput';
import AvatarInput from '../FormComponents/AvatarInput';

const required = value => (value === undefined ? 'Required' : undefined)
const requiredIfNew = (value,allValues )=>(allValues._id||value ? undefined : 'Required')
const matchPass = (value,allValues) => {
   return allValues.password&&allValues.password!==value ? 'Must match the password provided' : undefined
}
const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined
const minLength2 = minLength(2)
const minLength3 = minLength(3)
const minLength10 = minLength(10)
const noWhiteSpace = value =>
  value && /\s/.test(value) ? `Must not contain whitespace.` : undefined

let UserForm=props=>{
  const { handleSubmit, pristine, reset, submitting, goBack, auth } = props;
  return (
    <Form horizontal onSubmit={handleSubmit}>
      <Field 
        id="user-id" 
        label="User ID" 
        type="text" 
        className="form-control" 
        name="userid" 
        placeholder="user000" 
        validate={[required,minLength3]} 
        component={FieldInput}
        />
      <Field 
        id="user-name" 
        label="Name" 
        type="text" 
        className="form-control" 
        name="name" 
        placeholder="Name" 
        validate={[required,minLength10]} 
        component={FieldInput}
        />
      <Field 
        id="user-dep" 
        type="text" label="Position"
        name="department" 
        validate={[required]} 
        component={DropdownListInput} 
        data={['IT','CEO','Team leader','Quality','Technician','Admin','Inventory','Cleaning','Building','Operator']}
        />
      <Field 
        id="user-group" 
        label="Group"
        type="text" 
        className="form-control" 
        name="group" 
        placeholder="Group"
        validate={[required,minLength2,noWhiteSpace]} 
        component={FieldInput}
        />
      {auth.type==='admin'?
          (<Field
            name="type"
            component={SelectListInput}
            label="User type"
            textField="label"
            valueField="value"
            data={[
              {label: 'Admin',value: 'admin'},
              {label: 'Supervisor',value: 'supervisor'},
              {label: 'User',value: 'user'}
              ]}
            required={true}
            />)
          :(<Field
            name="type"
            component={FieldStatic}
            label="User type"
            />)  
      }
      <Field 
        id="user-password" 
        label="Password" 
        type="password" 
        className="form-control" 
        glyph="key" 
        name="password" 
        placeholder="Password" 
        validate={[requiredIfNew]} 
        component={FieldInput}
        />
      <Field 
        id="user-repassword" 
        label="Confirm password" 
        type="password" 
        className="form-control" 
        glyph="key" 
        name="repassword" 
        placeholder="Password" 
        validate={[matchPass]} 
        component={FieldInput}
        /> 
      <Field
        name="locked"
        component={SelectListInput}
        label="Block"
        textField="label"
        valueField="value"
        data={[
          {label: 'Yes',value: true},
          {label: 'No',value: false}
          ]}
        required={true}
        />  
      <Field
        name="password_expired"
        component={SelectListInput}
        label="Expired password"
        textField="label"
        valueField="value"
        data={[
          {label: 'Yes',value: true},
          {label: 'No',value: false}
          ]}
        required={true}
        />  
      <Field
        id="user-avatar" 
        label="Picture" 
        type="image" 
        className="form-control" 
        name="avatar" 
        component={AvatarInput}
        />  
      <FormGroup className="form-actions">
        <Button type="submit" bsStyle="primary" disabled={pristine || submitting}>Submit</Button>
        <Button type="button" bsStyle="default" disabled={pristine || submitting} onClick={reset}>
          Undo Changes
        </Button>
        <Button className="pull-right" onClick={goBack}>Back</Button>
      </FormGroup>
    </Form>
  );
}

export default reduxForm({
  form:'userForm'
})(UserForm);