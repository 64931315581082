import React from 'react';
import { Form, FormGroup, Button } from 'react-bootstrap';
import FieldInput from '../FormComponents/FieldInput';
import DateTimeInput from '../FormComponents/DateTimeInput';
import DropdownListInput from '../FormComponents/DropdownListInput';
import { Field, reduxForm } from 'redux-form';
import momentLocalizer from 'react-widgets-moment';
import moment from 'moment';
import SelectListInput from '../FormComponents/SelectListInput';
momentLocalizer(moment);

const required = value => (value === undefined ? 'Required' : undefined);
const minLength1 = value =>
      value && value.length < 1 ? `Must be 1 characters or more` : undefined;
const validProd = value => (value && value._id ? undefined : 'Required');
    
const ListItem = ({item}) => {
  if(!item._id) {
    return <span>Select</span>;
  } else {
    return (
      <div>
        <p><strong>Operation: </strong>{item.op_code} {item.op_desc&& ` - ${item.op_desc}`}</p>
        <p><strong>Article: </strong>{item.prod_code} {item.prod_desc&& ` - ${item.prod_desc}`}</p>
        <p><strong>Department: </strong>{item.department}</p>
      </div>
    );
  }
}

class WorkItemForm extends React.Component {
 
  render(){
    let date_limit_min=moment(this.props.month+'01').toDate();
    let date_limit_max=moment(this.props.month+'01').endOf('month').toDate();
    const { handleSubmit, pristine, submitting, products, working_time } = this.props;
    return (
      <Form horizontal onSubmit={handleSubmit}>
        <h1>Report of work</h1>
        <Field 
          id="date" name="date" label="Date"
          type="text" component={DateTimeInput}
          validate={[required]}
          date_format="YYYY-MM-DD"
          time={false}
          month={this.props.month}
          min={date_limit_min}
          max={date_limit_max}
        />
        <Field
          id="product" name="product" label="Article no."
          className="form-control" 
          component={DropdownListInput} 
          textField={item=>{
            return !item.op_code?'':`${item.op_code} ${item.op_desc} ${item.prod_code} ${item.prod_desc} ${item.department}`
          }}
          valueField={item=>item&&item._id}
          filter={(x,y)=>{
            let s=y.toLowerCase().split(' ');
            let t=`${x.prod_code} ${x.prod_desc} ${x.op_code} ${x.op_desc} ${x.department}`.toLowerCase();
            return s.reduce((a,b)=>a&&t.indexOf(b)>-1,true);
          }} 
          itemComponent={ListItem}
          valueComponent={ListItem}
          groupBy='department'
          data={products}
          validate={[required,validProd]} />
        <Field 
          id="order-number" name="order_number"  label="Order Number / Work description" 
          type="text" className="form-control" placeholder="Order #" validate={[required,minLength1]} component={FieldInput}/>
        <Field 
          id="pieces" name="pieces" label="Pcs / Hours" 
          type="text" className="form-control" placeholder="# of pieces / hours" validate={[required]} component={FieldInput}/>
        <Field
          id="workingtime" name="working_time" label="Working Time"
          data={working_time}
          textField="name"
          type="text" 
          validate={[required]} 
          component={SelectListInput} 
          />
        <FormGroup className="form-actions">
          <Button type="submit" bsStyle="primary" disabled={pristine || submitting}>
            {this.props.initialValues&&this.props.initialValues._id?'Save':'Create'}
          </Button>
          {/* <Button className="pull-right" onClick={this.props.logout}>Logout</Button> */}
          <Button className="pull-right" onClick={this.props.goBack}>Close</Button>
        </FormGroup>
      </Form>
    );
  }
}

export default reduxForm({form:'workItemForm'})(WorkItemForm);