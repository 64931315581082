import {
  USER_GET_REQUEST, USER_GET_SUCCESS, USER_GET_FAIL,
  USER_SET_REQUEST, USER_SET_SUCCESS, USER_SET_FAIL,
  USERS_GET_REQUEST, USERS_GET_SUCCESS, USERS_GET_FAIL,
  USER_DELETE_REQUEST, USER_DELETE_SUCCESS, USER_DELETE_FAIL
} from './UsersActions';
import {USER_LOGOUT_SUCCESS } from '../Auth/AuthActions'


const initialState={
  users_loading:false,
  users_loaded:false,
  user_loading:false,
  user_loaded:false,
  user_saving:false,
  user_saved:false,
  user_deleting:false,
  user_deleted:false,
  items:[],
  user:null
};

export default (state=initialState,action)=>{
  switch (action.type){
    case USERS_GET_REQUEST:
      return Object.assign({},state,{
        users_loading:true,
        users_loaded:false,
        user:null,
        items:[]
      });
    case USERS_GET_SUCCESS:
      return Object.assign({},state,{
        users_loading:false,
        users_loaded:true,
        items:[...action.response.users],
        user:null
      });
    case USERS_GET_FAIL:
      return Object.assign({},state,{
        users_loading:false,
        users_loaded:false,
        items:[],
        user:null
      });
      case USER_GET_REQUEST:
      return Object.assign({},state,{
        user_loading:true,
        user_loaded:false,
        user:null
      });
    case USER_GET_SUCCESS:
      return Object.assign({},state,{
        user_loading:false,
        user_loaded:true,
        user:{...action.response.user}
      });
    case USER_GET_FAIL:
      return Object.assign({},state,{
        user_loading:false,
        user_loaded:false,
        user:null
      });
    case USER_SET_REQUEST:
      return Object.assign({},state,{
        user_saving:true,
        user_saved:false
      });
    case USER_SET_SUCCESS:
      return Object.assign({},state,{
        user_saving:false,
        user_saved:true,
        user:{...action.response.user}
      });
    case USER_SET_FAIL:
      return Object.assign({},state,{
        user_saving:false,
        user_saved:false
      });
      case USER_DELETE_REQUEST:
      return Object.assign({},state,{
        user_deleting:true,
        user_deleted:false
      });
    case USER_DELETE_SUCCESS:
      return Object.assign({},state,{
        user_deleting:false,
        user_deleted:true,
        user:null
      });
    case USER_DELETE_FAIL:
      return Object.assign({},state,{
        user_deleting:false,
        user_deleted:false
      });
    case USER_LOGOUT_SUCCESS:
      return Object.assign({},initialState);
    default:
      return state;
  }
}