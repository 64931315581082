import React from 'react';
import { browserHistory } from 'react-router';
import "./IconButton.css";

const IconButton = ({ type, redirect, onClick, right }) => {
  let style = {};
  if (redirect)onClick=()=>browserHistory.push(redirect);
  if (right)style['float']='right';

  return (
    <div className={`image-button ib-${type}`} style={style} onClick={onClick} />
  )
}

export default IconButton;