import ReportViewer from './ReportViewer.js';
import { connect } from 'react-redux';
import { getReport } from './ReportsActions';

const mapStateToProps=(state)=>{
  return {
    report_loaded:state.reports.report_loaded,
    report:state.reports.report
  }
}

const mapDispatchToProps=(dispatch)=>({
  getReport(type,params){dispatch(getReport(type,params))}
})

export default connect(mapStateToProps,mapDispatchToProps)(ReportViewer);