import React, { Component } from 'react';
import ProductForm from './ProductForm.js';
import { Row, Col, Panel, Alert } from 'react-bootstrap';

class Product extends Component{
  
  componentDidMount(){
    this.props.getProduct(this.props.params.id);
  }

  render(){
    return (
      <div>
        <Row>
          <Col xs={12} sm={6} smOffset={3}>
            <Panel>
              <Col xs={12}>
                <ProductForm 
                  onSubmit={this.props.setProduct} 
                  initialValues={this.props.initialValues}
                  shipping_costs={this.props.shipping_costs}
                  goBack={this.props.goBack}
                  />
                {this.props.product_failed&&
                  <Row>
                    <Alert bsStyle='danger'>
                      Product could not be loaded!
                    </Alert>
                  </Row>
                }
              </Col>
            </Panel>
          </Col>
        </Row>
      </div>
    )
  }
}

export default Product;