import React from 'react';
import moment from 'moment';
import { FormGroup, ControlLabel, HelpBlock, Col } from 'react-bootstrap';
import { DateTimePicker } from 'react-widgets';

const DateTimeInput = ({ input: { onChange, value }, meta, placeholder, min, max, name, error, id, label, time, month, date_format, views }) => {
  var validationState=null,help=null;

  if (meta.touched&&meta.error){
    validationState="warning";
    help=(
      <HelpBlock>{meta.error}</HelpBlock>
    )
  }
  let today = moment();
  let date = !month || today.format('YYYYMM') === (''+month) ? today : moment(month+'01');
  return (
    <FormGroup validationState={validationState}>
      <Col componentClass={ControlLabel} sm={4}>{label}</Col>
           {/* <ControlLabel>{label}</ControlLabel> */}
      <Col sm={8}>    
        <DateTimePicker
          id={id}
          name={name||null}
          time={time||false}
          onChange={onChange}
          format={date_format}
          value={value?new Date(value):date.toDate()}
          min={min}
          max={max}
          views={views}
          />
      </Col>
      {help}
    </FormGroup>
  )
}

export default DateTimeInput;