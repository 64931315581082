import React, { Component } from 'react';
import { Table, Row, Col, Modal, Button, Image } from 'react-bootstrap';
import { browserHistory } from 'react-router';
import { DropdownList } from 'react-widgets';
import IconButton from '../FormComponents/IconButton';

class Users extends Component{
  constructor(props){
    super(props);
    this.props.loadUsers();
    let { blocked, expired } = this.props.location.query;
    this.state = {
      deleteModal: false,
      modalMessage: "",
      modalCallback: () => false,
      filter: [],
      blocked: !blocked ? false : blocked === 'true',
      expired: !expired ? false : expired === 'true'
    }
  }

  handleBlockedChange(opt){
    let blocked=opt.v;
    this.setState({page:1,blocked});
    browserHistory.push({
      pathname: this.props.location.pathname,
      query: {
        ...this.props.location.query,
        page:1,
        blocked
      }
    })
  }

  handleExpiredChange(opt){
    let expired = opt.v;
    this.setState({ page: 1, expired });
    browserHistory.push({
      pathname: this.props.location.pathname,
      query: {
        ...this.props.location.query,
        page:1,
        expired
      }
    })
  }

  render(){
    const closeModal=()=>{this.setState({deleteModal:false})}
    if (this.props.users_loaded){
      const filteredUsers = this.props.users.filter(user => {
        let { userid, name, department, type, group, locked, password_expired } = user;
        const search_string = `${userid} ${name} ${department} ${type} ${group}
          ${locked?'blocked':'enabled'} ${password_expired?'expired':'active'}`.toLowerCase();
        let match = ((this.state.blocked == null) || (this.state.blocked === locked)) &&
                    ((this.state.expired == null) || (this.state.expired === password_expired));

        this.state.filter.forEach(fi=>{
          match = match && (search_string.indexOf(fi) > -1);
        });
        return match;
      });
      return (
        <div id="users">
            <Row>
              <Col xs={6}>
                <h1>Users</h1>
              </Col>
              <Col xs={6}>
                <IconButton type="create" redirect="/users/new" right>Create</IconButton>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={3}>
                <label htmlFor="filter">Search</label><br />
                <input name="filter" 
                  className="form-control" 
                  type="text" 
                  placeholder="Filter" 
                  onChange={v=>{this.setState({filter:v.target.value.toLowerCase().split(" ")})}}/>
              </Col>
              <Col xs={12} sm={3}>
                <label htmlFor="blocked">Blocked</label><br />
                <DropdownList name="blocked"
                  data={[{l:'all',v:null},{l:'blocked',v:true},{l:'enabled',v:false}]}
                  value={this.state.blocked}
                  textField="l"
                  valueField="v"
                  onChange={this.handleBlockedChange.bind(this)} />
              </Col>  
              <Col xs={12} sm={3}>
                <label htmlFor="expired">PW Expired</label><br />
                <DropdownList name="expired"
                  data={[{l:'all',v:null},{l:'expired',v:true},{l:'active',v:false}]}
                  value={this.state.expired}
                  textField="l"
                  valueField="v"
                  onChange={this.handleExpiredChange.bind(this)} />
              </Col>  
              <Col xs={12} sm={3} >
                <label>Records found (total)</label>
                <h4>{filteredUsers.length} ( {this.props.users.length} )</h4>
              </Col>

            </Row>
            <Table className="crud-table" >
              <thead>
                <tr>
                  <th></th>
                  <th>User ID</th>
                  <th>Name</th>
                  <th className="hidden-xs">Department</th>
                  <th className="hidden-xs">Group</th>
                  <th>Type</th>
                  <th className="hidden-xs">Blocked</th>
                  <th className="hidden-xs">PW Expired</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers.map(user=>{
                  return (
                    <tr key={user._id}>
                      <td><Image width={50} height={50} src={user.avatar||"/img/profile.jpg"} rounded/></td>
                      <td>{user.userid}</td>
                      <td>{user.name}</td>
                      <td className="hidden-xs">{user.department}</td>
                      <td className="hidden-xs">{user.group}</td>
                      <td>{user.type}</td>
                      <td className="hidden-xs">{user.locked?'blocked':'enabled'}</td>
                      <td className="hidden-xs">{user.password_expired?'expired':'active'}</td>
                      <td>
                        {this.props.auth.type==='admin'&&(
                        <IconButton type="delete" onClick={()=>{
                          this.setState({
                            deleteModal:true,
                            modalMessage:`Are you sure you want to delete ${user.name}? This action is permanent.`,
                            modalCallback:()=>{this.props.deleteUser(user._id);closeModal();}
                          });
                        }}>Delete</IconButton>
                        )}
                        <IconButton type="edit" redirect={"/users/"+user._id}>Edit</IconButton>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
            <Modal show={this.state.deleteModal} onHide={closeModal}>
              <Modal.Header>Confirm delete...</Modal.Header>
              <Modal.Body>{this.state.modalMessage}</Modal.Body>
              <Modal.Footer>
                <Button onClick={closeModal}>Cancel</Button>
                <Button onClick={this.state.modalCallback}>Confirm</Button>
              </Modal.Footer>
            </Modal>
        </div>
      )
    }
    else {
      return (
        <div className="loading">

        </div>
      );
    }
  }
}

export default Users;