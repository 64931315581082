import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { browserHistory } from 'react-router';
import configureStore from './storeConfig';
import registerServiceWorker from './registerServiceWorker';
import { Provider } from 'react-redux';
import { Router, Route, IndexRedirect } from 'react-router';
import App from './App/AppContainer';
import Landing from './Landing/LandingContainer';
import Profile from './Profile/ProfileContainer';
import Product from './Products/ProductContainer';
import Products from './Products/ProductsContainer';
import Report from './Reports/ReportContainer';
import ReportViewer from './Reports/ReportViewerContainer';
import User from './Users/UserContainer';
import Users from './Users/UsersContainer';
import WorkSheet from './WorkSheets/WorkSheetContainer';
import WorkSheets from './WorkSheets/WorkSheetsContainer';
import WorkItem from './WorkItem/WorkItemContainer';
import {loginUser} from './Auth/AuthActions';
import { routerActions } from 'react-router-redux';
import { connectedReduxRedirect } from 'redux-auth-wrapper/history3/redirect';
import locationHelperBuilder from 'redux-auth-wrapper/history3/locationHelper';
const locationHelper = locationHelperBuilder({});

const userIsAdmin = connectedReduxRedirect({
  redirectPath:'/worksheets',
  authenticatedSelector: state => state.auth.user.type==="admin",
  authenticatingSelector: state => state.auth.authenticating,
  redirectAction: routerActions.push,
  wrapperDisplayName: 'UserIsAdmin'
});

const userIsSupervisor = connectedReduxRedirect({
  redirectPath:'/worksheets',
  authenticatedSelector: state => state.auth.user.type==="admin" || state.auth.user.type==="supervisor",
  authenticatingSelector: state => state.auth.authenticating,
  redirectAction: routerActions.push,
  wrapperDisplayName: 'UserIsSupervisor'
});

const userLoggedIn = connectedReduxRedirect({
  redirectPath:'/home',
  authenticatedSelector: state => state.auth.authenticated,
  authenticatingSelector: state => state.auth.authenticating,
  redirectAction: routerActions.push,
  wrapperDisplayName: 'UserLoggedIn'
});

const userNotLoggedIn = connectedReduxRedirect({
  redirectPath: (state, ownProps) => {
    let alt = (state.auth.user.type==='user' ? '/worksheets/current': '/worksheets');
    return locationHelper.getRedirectQueryParam(ownProps) || alt;
  },
  allowRedirectBack: false,
  authenticatedSelector: state => !state.auth.authenticated,
  redirectAction: routerActions.push,
  wrapperDisplayName: 'UserNotLoggedIn'
})

const { store, history }=configureStore(browserHistory, window.__INITIAL_STATE__);

const Root=(
  <div className="root-div">
    <Provider store={store}>
      <Router history={history}>
        <Route path="/" component={App}>
          <IndexRedirect to="/home" />
          <Route path="home" component={userNotLoggedIn(Landing)} />
          <Route path="worksheets" component={userLoggedIn(WorkSheets)} />
          <Route path="worksheets/:id" component={userLoggedIn(WorkSheet)} />
          <Route path="worksheets/:worksheet_id/items/:id" component={userLoggedIn(WorkItem)} />
          <Route path="users" component={userLoggedIn(userIsSupervisor(Users))} />
          <Route path="users/:id" component={userLoggedIn(userIsSupervisor(User))} />
          <Route path="products" component={userLoggedIn(Products)} />
          <Route path="products/:id" component={userLoggedIn(userIsAdmin(Product))}/>
          <Route path="reports" component={userLoggedIn(userIsSupervisor(Report))} />
          <Route path="reports/:type" component={userLoggedIn(userIsSupervisor(ReportViewer))} />
          <Route path="profile" component={userLoggedIn(Profile)} />
        </Route>
      </Router>
    </Provider>
  </div>
);

var token = localStorage.getItem('token');
if (token !== null&&token!==undefined) {
  store.dispatch(loginUser());
}

ReactDOM.render(Root, document.getElementById('root'));

registerServiceWorker();
