import {
  USER_LOGIN_REQUEST, 
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT_SUCCESS } from './AuthActions'

const initialState={
  user:{},
  authenticating:false,
  authenticated:false,
  auth_failed:false,
  password_expired:false,
  message:''
};

export default (state=initialState,action)=>{
  switch (action.type){
    case USER_LOGIN_REQUEST:
      return Object.assign({},state,{
        authenticating:true,
        authenticated:false,
        auth_failed:false,
        user:{},
        message:''
      });
    case USER_LOGIN_SUCCESS:
      return Object.assign({},state,{
        user:{...action.user},
        authenticated:true,
        authenticating:false,
        auth_failed:false,
        password_expired:false,
        message:''
      });
      case USER_LOGIN_FAIL:
      return Object.assign({},state,{
        user:{},
        authenticated:false,
        authenticating:false,
        auth_failed:true,
        password_expired:action.password_expired,
        message: action.message
      });
      case USER_LOGOUT_SUCCESS:
      return Object.assign({},state,{
        user:{},
        authenticated:false,
        authenticating:false,
        password_expired:false,
        message:''
        // auth_failed:true
      });
    default:
      return state;
  }
}

