import Product from './Product';
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';
import { getProduct, setProduct } from './ProductsActions';

const mapStateToProps=(state)=>{
  return {
    initialValues:state.products.product,
    product_saved:state.products.product_saved,
    shipping_costs:state.app.constants.shipping_costs_ppl_sek
  }
}

const mapDispatchToProps=(dispatch)=>{
  return {
    getProduct:(id)=>{dispatch(getProduct(id))},
    setProduct:(prod)=>{dispatch(setProduct(prod))},
    goBack:()=>{dispatch(goBack())}
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Product);